import React from 'react';
import { Button, TextField, CircularProgress, Grid } from '@mui/material';
import { useAuth, Credentials } from './AuthProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import { WelcomePage } from './loginPage';

interface IProps {}

interface SignupFormProps {
  onSignup(): void;
  onChange(evt: React.SyntheticEvent, field: string): void;
  credentials: Credentials;
}
type SignupFlows = 'email-with-password' | 'email-link';
// TODO(JK): If user already exists (i.e they purchased a product) prompt it looks like you already have an account. Send password reset and promote to admin
const SignupForm = (props: SignupFormProps) => {
  const auth = useAuth();
  const [firebaseError, setFirebaseError] = React.useState('');
  const [signupFlow, setSignupFlow] = React.useState<SignupFlows>(
    'email-with-password'
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [successMessage, setSuccessMessage] = React.useState<string>('');
  const signupDisabled = React.useMemo(() => {
    if (signupFlow === 'email-link') {
      return !props.credentials.email.length;
    }
    return (
      !props.credentials.email.length ||
      !props.credentials.password.length ||
      !props.credentials.confirmPassword?.length ||
      props.credentials.password !== props.credentials.confirmPassword
    );
  }, [
    props.credentials.email,
    props.credentials.password,
    props.credentials.confirmPassword,
  ]);
  const signup = async (creds) => {
    try {
      setIsLoading(true);
      const user = await auth.signUp(creds, true);
      setFirebaseError('');
      setIsLoading(false);

      // props.onSignup();
    } catch (e: any) {
      setIsLoading(false);

      if (e.code == 'auth/invalid-email') {
        setFirebaseError('Invalid Email');
      } else if (e.code === 'auth/weak-password') {
        setFirebaseError('Weak password. Please enter a stronger password.');
      } else if (e.code == 'auth/email-already-in-use') {
        setFirebaseError('Email already in use. Forgot password?');
      } else {
        setFirebaseError(`Unknown error occurred: ${e.code}`);
      }
    }
  };
  const signUpWithLinkToEmail = async (creds: Credentials) => {
    try {
      setIsLoading(true);
      setFirebaseError('');
      setSuccessMessage('');
      const user = await auth.signUpWithLinkToEmail(creds.email);
      setIsLoading(false);
      setSuccessMessage(
        `An email confirmation has been sent to ${creds.email}. Please click the link in your email to confirm your account.`
      );
    } catch (e: any) {
      console.error('error here: ', e);
      setIsLoading(false);
      if (e.code == 'auth/invalid-email') {
        setFirebaseError('Invalid Email');
      } else if (e.code === 'auth/weak-password') {
        setFirebaseError('Weak password. Please enter a stronger password.');
      } else if (e.code == 'auth/email-already-in-use') {
        setFirebaseError('Email already in use. Forgot password?');
      } else {
        setFirebaseError(`Unknown error occurred: ${e.code}`);
      }
    }
  };
  const handleSignupSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    if (signupFlow === 'email-link') {
      signUpWithLinkToEmail(props.credentials);
    } else {
      signup(props.credentials);
    }
  };
  return (
    <form onSubmit={handleSignupSubmit} style={{ width: '300px' }}>
      <h2>
        JAXLet's get your account setup. All we need is an email to get started
      </h2>
      <div>
        <TextField
          fullWidth
          margin="normal"
          variant="standard"
          label="Email"
          type="email"
          value={props.credentials.email}
          onChange={(evt) => props.onChange(evt, 'email')}
        />
      </div>
      {signupFlow === 'email-with-password' && (
        <>
          <div>
            <TextField
              fullWidth
              variant="standard"
              margin="normal"
              label="Password"
              type="password"
              value={props.credentials.password}
              onChange={(evt) => props.onChange(evt, 'password')}
            />
          </div>
          <div>
            <TextField
              fullWidth
              variant="standard"
              margin="normal"
              label="Confirm Password"
              type="password"
              value={props.credentials.confirmPassword}
              onChange={(evt) => props.onChange(evt, 'confirmPassword')}
            />
          </div>
        </>
      )}
      <Button type="submit" disabled={signupDisabled || isLoading}>
        Sign Up
        {isLoading && <CircularProgress color="success" size={14} />}
      </Button>
      <div style={{ color: 'green' }}>{successMessage}</div>

      <div style={{ color: 'red' }}>{firebaseError}</div>
    </form>
  );
};

export const Login: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore
  const from = location.state?.from?.pathname || '/client';

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12} md={6}>
        {/* <NewToImage /> */}
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10} lg={8}>
            <WelcomePage
              onSignin={() => {
                navigate(from, { replace: true });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
