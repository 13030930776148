import React from 'react';
import { Page } from '../../UI/PageLayout/Page';
import { useSigninCheck } from 'reactfire';
import { useTransactionHistory } from '../products/useProducts';

export const Analytics: React.FC = (props) => {
  const { data: fireUser } = useSigninCheck();
  console.log('fireUser: ', fireUser);
  const { status, data: transactions } = useTransactionHistory(
    fireUser.user?.uid
  );
  if (status === 'loading') {
    return <div>Loading transactions</div>;
  }
  console.log('transactions: ', transactions);
  return (
    <Page.Wrapper>
      <Page.Header>
        <Page.Title>Analytics</Page.Title>
      </Page.Header>
      <Page.Content>
        {transactions.map((transaction) => (
          <div key={transaction.id}>
            {transaction.userEmail} ::: {transaction.productId} :::{' '}
            {transaction.purchaseDate}
          </div>
        ))}
      </Page.Content>
    </Page.Wrapper>
  );
};
