export const formatCurrency = (value, decimalDigits?: number) => {

    const maximumFractionDigits = decimalDigits || 0;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits
    });

    return formatter.format(value);

}

export const validateCurrency = (
    {
        value, 
        decimalDigits
    } : 
    {
        value: any, 
        decimalDigits?: number
    }) => {
        
    const re = /^[0-9\b]+$/;
    // const str = "^\d+(\.\d{1," + (decimalDigits || 3) + "})?$";
    // const re = new RegExp(str);
    // const re = new RegExp("^\d+(\.\d{1,3})?$");

    if(isLastDigitDecimal(value)) value += '000';

    return value === '' || re.test(value);

}

const isLastDigitDecimal = (value) => {

    return value.indexOf('.') === (value.length - 1);

}