import { useState } from 'react';
import { useQuery } from 'react-query';
import { collection, addDoc, query, orderBy, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import uniqueId from 'uniqueid';
import Widgets from '../../lib';
import { HOSTNAME } from '../../index';
import { accessLevel, WidgetTypes } from '../../lib/Widgets/Widgets';
import { useAuth } from '../Login/AuthProvider';

export type Product = {
  id: string;
  name: string;
  type: 'mortgageLoanCalculator' | 'carLoanCalculator';
  url: string;
  shareable_url: string;
};
export type TransactionInfo = {
  productId: string;
  userId: string;
  userEmail: string;
  transactionStatus: 'pending' | 'trial' | 'succeeded';
  stripePaymentIntentId: string;
  stripeCustomerId: string;
  referrerUserId: string;
};
const TEMP_PRODUCTS: Product[] = [
  {
    id: '1',
    name: 'Mortgage Calculator',
    type: 'mortgageLoanCalculator',
    shareable_url: `${window.location.origin}/shared/1/`,
    url: '/shared/1/',
  },
  {
    id: '2',
    name: 'Car Loan Calculator',
    type: 'carLoanCalculator',
    shareable_url: `${window.location.origin}/shared/2/`,
    url: '/shared/2/',
  },
  {
    id: '3',
    name: 'Turo Calculator',
    type: 'carLoanCalculator',
    shareable_url: `${window.location.origin}/shared/3/`,
    url: '/shared/3/',
  },
];
const uuid = uniqueId('unique_code');
const productIdToName: Record<number, WidgetTypes> = {
  1: 'mortgageCalculator',
  2: 'carLoanCalculator',
  3: 'turoCalculator',
};
export const useProducts = () => {
  return useQuery('products', async () => {
    const res = await fetch(`${HOSTNAME}/api/products`);
    const { data } = await res.json();
    return data;
    // return Promise.resolve(
    //   TEMP_PRODUCTS.map((product) => {
    //     const tempUuid = uuid();
    //     return {
    //       ...product,
    //       shareable_url: `${product.shareable_url}${tempUuid}`,
    //       url: `${product.url}${tempUuid}`,
    //     };
    //   })
    // );
  });
};

export const useProduct = (id: string) => {
  return useQuery(['products', id], async () => {
    const res = await fetch(`${HOSTNAME}/api/products/${id}`);
    const { data } = await res.json();
    return data;
    // return Promise.resolve(TEMP_PRODUCTS.find((prod) => prod.id === id));
  });
};

export const useTransactionHistory = (id?: string) => {
  const firestore = useFirestore();
  const transactionCollection = collection(firestore, 'transactions');
  const transactionsQuery = query(
    transactionCollection,
    where('referrerUserId', '==', id)
  );
  return useFirestoreCollectionData(transactionsQuery, {
    idField: 'id', // this field will be added to the object created from each document
  });
};

export const useUserProductAccess = ({ productId, referrerUserId }) => {
  const { user } = useAuth();
  const firestore = useFirestore();
  const transactionsCollection = collection(firestore, 'transactions');
  const transactionsQuery = query(
    transactionsCollection,
    where('userId', '==', user?.uid || ''),
    where('productId', '==', productId),
    where('referrerUserId', '==', referrerUserId)
  );
  return useFirestoreCollectionData(transactionsQuery, {
    idField: 'id', // this field will be added to the object created from each document
  });
};

export const usePurchaseProduct = () => {
  const firestore = useFirestore();
  return async (transactionInfo: TransactionInfo) => {
    try {
      const date = new Date();
      const docRef = await addDoc(collection(firestore, 'transactions'), {
        ...transactionInfo,
        purchaseTimestamp: date.getTime(),
        purchaseDate: date.toISOString(),
      });
      return docRef;
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };
};

export const useProductRenderer = (
  id: string,
  selector: string,
  accessLevel: accessLevel
) => {
  const [productRenderer] = useState(
    Widgets.widgets[productIdToName[id] as WidgetTypes].new({
      selector,
      auth: accessLevel,
      authUrl: `${window.location.origin}/shared/paywall/${id}?return_url=${
        window.location.href.split('?')[0]
      }`,
    })
  );
  return productRenderer;
};
