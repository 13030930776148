import React from 'react';
import { Container, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignupPage } from './signupPage';

export const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore
  const from = location.state?.from?.pathname || '/client';
  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12} md={6}>
        <Container>
          <SignupPage
            onSignup={(user) => {
              if (user) {
                navigate(from);
              }
            }}
          />
        </Container>
      </Grid>
    </Grid>
  );
};
