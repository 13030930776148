import React from 'react';
import ReactDOM from 'react-dom';
import EventEmitter from 'events';
import uniqueid from 'uniqueid';
import AutoCalculator from '../Calculators/AutoCalculator/AutoCalculator';
import MortgageCalculator from '../Calculators/MortgageCalculator/MortgageCalculator';
import TuroCalculator from '../Calculators/TuroCalculator/TuroCalculator';

export type accessLevel = 'all' | 'trial' | 'none';
interface WidgetConfigs {
  apiKey?: string;
  auth?: accessLevel;
  authUrl?: string;
  selector: string;
}

interface WidgetApi {
  render: any;
  on: any;
  off: any;
  unmount: any;
}

interface WidgetDef {
  new: (config: WidgetConfigs) => WidgetApi;
}

export type WidgetTypes =
  | 'carLoanCalculator'
  | 'mortgageCalculator'
  | 'turoCalculator';
let JKConfig: WidgetConfigs = {
  selector: 'body',
};

const Widgets: {
  config(conf: WidgetConfigs): void;
  widgets: {
    carLoanCalculator: WidgetDef;
    mortgageCalculator: WidgetDef;
    turoCalculator: WidgetDef;
  };
} = {
  config: (config: WidgetConfigs) => {
    JKConfig = config;
  },

  widgets: {
    carLoanCalculator: {
      new: (config: WidgetConfigs) => {
        const selector = config.selector || JKConfig.selector;
        const apiKey = config.apiKey || JKConfig.apiKey;
        const carLoanEmitter = new EventEmitter();

        const uid = uniqueid('widget_jk_');

        const onrender = () => {
          carLoanEmitter.emit(`${uid}.render`);
        };
        const ondestroy = () => {
          carLoanEmitter.emit(`${uid}.destroy`);
        };

        return {
          render: () => {
            ReactDOM.render(
              <AutoCalculator
                onrender={onrender}
                ondestroy={ondestroy}
                apikey={apiKey}
              />,
              document.querySelector(selector)
            );
          },
          on: (event: EventEmitter, callback: any) => {
            carLoanEmitter.on.apply(carLoanEmitter, [
              `${uid}.${event}`,
              callback,
            ]);
          },
          off: (event: EventEmitter, callback: any) => {
            carLoanEmitter.removeListener.apply(carLoanEmitter, [
              `${uid}.${event}`,
              callback,
            ]);
          },
          unmount: () => {
            ReactDOM.unmountComponentAtNode(document.querySelector(selector)!);
          },
        };
      },
    },
    mortgageCalculator: {
      new: (config: WidgetConfigs) => {
        const selector = config.selector || JKConfig.selector;
        const apiKey = config.apiKey || JKConfig.apiKey;
        const mortgageCalcEmitter = new EventEmitter();

        const uid = uniqueid('widget_jk_');

        const onrender = () => {
          mortgageCalcEmitter.emit(`${uid}.render`);
        };
        const ondestroy = () => {
          mortgageCalcEmitter.emit(`${uid}.destroy`);
        };

        return {
          render: () => {
            ReactDOM.render(
              <MortgageCalculator
                onrender={onrender}
                ondestroy={ondestroy}
                apikey={apiKey}
              />,
              document.querySelector(selector)
            );
          },
          on: (event: EventEmitter, callback: any) => {
            mortgageCalcEmitter.on.apply(mortgageCalcEmitter, [
              `${uid}.${event}`,
              callback,
            ]);
          },
          off: (event: EventEmitter, callback: any) => {
            mortgageCalcEmitter.removeListener.apply(mortgageCalcEmitter, [
              `${uid}.${event}`,
              callback,
            ]);
          },
          unmount: () => {
            ReactDOM.unmountComponentAtNode(document.querySelector(selector)!);
          },
        };
      },
    },
    turoCalculator: {
      new: (config: WidgetConfigs) => {
        console.log('loading turo calculator');
        const selector = config.selector || JKConfig.selector;
        const apiKey = config.apiKey || JKConfig.apiKey;
        const turoCalculatorEmitter = new EventEmitter();

        const uid = uniqueid('widget_jk_');

        const onrender = () => {
          turoCalculatorEmitter.emit(`${uid}.render`);
        };
        const ondestroy = () => {
          turoCalculatorEmitter.emit(`${uid}.destroy`);
        };

        return {
          render: () => {
            ReactDOM.render(
              <TuroCalculator
                onrender={onrender}
                accessLevel={config.auth || 'none'}
                authUrl={config.authUrl}
                ondestroy={ondestroy}
                apikey={apiKey}
              />,
              document.querySelector(selector)
            );
          },
          on: (event: EventEmitter, callback: any) => {
            turoCalculatorEmitter.on.apply(turoCalculatorEmitter, [
              `${uid}.${event}`,
              callback,
            ]);
          },
          off: (event: EventEmitter, callback: any) => {
            turoCalculatorEmitter.removeListener.apply(turoCalculatorEmitter, [
              `${uid}.${event}`,
              callback,
            ]);
          },
          unmount: () => {
            ReactDOM.unmountComponentAtNode(document.querySelector(selector)!);
          },
        };
      },
    },
  },
};

export default Widgets;
