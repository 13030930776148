import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Page } from '../../UI/PageLayout/Page';
import { useProducts } from './useProducts';
import { AiOutlineCopy } from 'react-icons/ai';
import { Alert, IconButton, Snackbar } from '@mui/material';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { useAuth } from '../Login/AuthProvider';

interface ProductProps {}

export const Products: React.FC<ProductProps> = () => {
  const { data: products } = useProducts();
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();

  const handleCloseSnackbar = () => {
    setIsOpen(false);
  };
  const copyUrlToClipboard = (url: string) => {
    window.navigator.clipboard.writeText(url);
    setIsOpen(true);
  };

  return (
    <Page.Wrapper>
      <Page.Header>
        <Page.Title>Products</Page.Title>
      </Page.Header>
      <Page.Content>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Shareable URL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    <div>{product.name}</div>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        to={`${product.url}${auth.user?.uid}`}
                        target="_blank"
                      >
                        {`${window.location.origin}${product.url}${auth.user?.uid}`}
                      </Link>
                      <IconButton
                        onClick={() =>
                          copyUrlToClipboard(
                            `${window.location.origin}${product.url}${auth.user?.uid}`
                          )
                        }
                      >
                        <AiOutlineCopy />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Page.Content>
      <Snackbar
        open={isOpen}
        onClose={handleCloseSnackbar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="info">Copied Url to clipboard</Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};
