import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  BrowserRouter as Router,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  FirebaseAppProvider,
  DatabaseProvider,
  AnalyticsProvider,
  AuthProvider as FirebaseAuthProvider,
  useFirebaseApp,
  useAnalytics,
  AppCheckProvider,
  useInitFirestore,
  FirestoreProvider,
} from 'reactfire';

// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth } from 'firebase/auth'; // Firebase v9+
import { getDatabase } from 'firebase/database'; // Firebase v9+
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import {
  initializeFirestore,
  enableIndexedDbPersistence,
} from 'firebase/firestore';
import { AuthProvider } from './pages/Login/AuthProvider';
import { PaywallProvider } from './pages/Paywall/PaywallProvider';
import { WithBugsnag } from './bugsnag/index';
import { LinkProps } from '@mui/material/Link';
import { ThemeProvider, createTheme } from '@mui/material';

const env: 'development' | 'production' = 'development';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme({
  // palette: {
  //   primary: {
  //     light: '#757ce8',
  //     main: '#714afc',
  //     dark: '#002884',
  //     contrastText: '#fff',
  //   },
  //   secondary: {
  //     light: '#ff7961',
  //     main: '#f44336',
  //     dark: '#ba000d',
  //     contrastText: '#000',
  //   },
  // },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let firebaseConfig = {
  apiKey: 'AIzaSyC65CxNWGkGBici3nHGbdmKV59TXNnL7Nc',
  authDomain: 'digatools-dev.firebaseapp.com',
  projectId: 'digatools-dev',
  storageBucket: 'digatools-dev.appspot.com',
  messagingSenderId: '879516014172',
  appId: '1:879516014172:web:5435d3c4611eeb32817899',
  measurementId: 'G-SQ0FWZGH7L',
};
export const HOSTNAME =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://us-central1-digitools-76230.cloudfunctions.net'
    : 'https://us-central1-digatools-dev.cloudfunctions.net';
if (process.env.REACT_APP_NODE_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyB9c4GMBaihfPVnrizfljRWe-iTlhWSQ3k',
    authDomain: 'digitools-76230.firebaseapp.com',
    projectId: 'digitools-76230',
    storageBucket: 'digitools-76230.appspot.com',
    messagingSenderId: '301737503283',
    appId: '1:301737503283:web:a8ede0cd9de55b68135146',
    measurementId: 'G-GBYLWBWFES',
  };
}

const MyPageViewLogger = ({ location }) => {
  const analytics = useAnalytics();

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  React.useEffect(() => {
    logEvent(analytics, 'page_view', { page_location: location.href });
  }, [location.href]);

  return null;
};
const APP_CHECK_TOKEN = '';
const FirebaseComponents = (props) => {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const { status, data: firestoreInstance } = useInitFirestore(
    async (firebaseApp) => {
      const db = initializeFirestore(firebaseApp, {});
      // await enableIndexedDbPersistence(db);
      return db;
    }
  );
  // Initialize Firebase
  // const appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
  //   isTokenAutoRefreshEnabled: true,
  // });
  // initialize Database and Auth with the normal Firebase SDK functions
  const database = getDatabase(app);
  const auth = getAuth(app);
  const analytics = getAnalytics(app);

  if (status === 'loading') {
    return <div>Loading databases...</div>;
  }
  // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
  return (
    // <AppCheckProvide sdk={appCheck}r>
    <AnalyticsProvider sdk={analytics}>
      <FirestoreProvider sdk={firestoreInstance}>
        <FirebaseAuthProvider sdk={auth}>
          <DatabaseProvider sdk={database}>{props.children}</DatabaseProvider>
        </FirebaseAuthProvider>
      </FirestoreProvider>
    </AnalyticsProvider>
    // </AppCheckProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <WithBugsnag>
    <ThemeProvider theme={theme}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <FirebaseComponents>
          <React.StrictMode>
            <Router>
              <AuthProvider>
                <PaywallProvider>
                  <App />
                </PaywallProvider>
              </AuthProvider>
              <MyPageViewLogger location={window.location} />
            </Router>
          </React.StrictMode>
        </FirebaseComponents>
      </FirebaseAppProvider>
    </ThemeProvider>
  </WithBugsnag>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
