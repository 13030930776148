import React from 'react';
import classes from './MortgageCalculator.module.scss';
import gridClasses from './../../shared/grid.module.scss';
import { formatCurrency } from './../../shared/utils/currency';

import { AMORTIZATION_VIEW, CALCULATOR_VIEW } from './MortgageConstants';
import * as CurrencyFormat from 'react-currency-format';
import DonutChart from './../../Widgets/DonutChart/DonutChart';

interface IProps {
  onrender: any;
  ondestroy: any;
  apikey?: string;
}

interface IState {
  [key: string]: any;
  visibleView: string;
  purchasePrice: number;
  loanAmount: number;
  downPaymentPercent: number;
  downPaymentDollar: number;
  propertyTaxPercent: number;
  propertyTaxDollar: number;
  propertyTaxDollarMonthly: number;
  hoaDues: number;
  mortgageInsurancePercent: number;
  mortgageInsuranceDollarMonthly: number;
  homeownersInsurancePercent: number;
  homeownersInsuranceDollarMonthly: number;
  interestRate: number;
  loanTermMonth: number;
  loanTermYear: number;
  principalAndInterest: number;
  monthlyPayment: number;
  amortizationChart: Array<Amortization>;
}

interface Amortization {
  payment: number;
  amount: number;
  interest: number;
  principal: number;
  balance: number;
}

class MortgageCalculator extends React.Component<IProps, IState> {
  public state: IState = {
    visibleView: CALCULATOR_VIEW,
    purchasePrice: 500000,
    loanAmount: 450000,
    downPaymentPercent: 10,
    downPaymentDollar: 50000,
    propertyTaxPercent: 1,
    propertyTaxDollar: 5000,
    propertyTaxDollarMonthly: 5000 / 12,
    hoaDues: 0,
    mortgageInsurancePercent: 0,
    mortgageInsuranceDollarMonthly: 0,
    homeownersInsurancePercent: 0.25,
    homeownersInsuranceDollarMonthly: 1250 / 12,
    interestRate: 4,
    loanTermMonth: 360,
    loanTermYear: 30,
    principalAndInterest: 0,
    monthlyPayment: 0,
    amortizationChart: [],
  };

  private sumMonthlyPayment = ({
    principalAndInterest,
    propertyTaxDollarMonthly,
    hoaDues,
    mortgageInsuranceDollarMonthly,
    homeownersInsuranceDollarMonthly,
  }: {
    principalAndInterest: number;
    propertyTaxDollarMonthly: number;
    hoaDues: number;
    mortgageInsuranceDollarMonthly: number;
    homeownersInsuranceDollarMonthly: number;
  }): number => {
    const sum: number =
      principalAndInterest +
      propertyTaxDollarMonthly +
      hoaDues +
      mortgageInsuranceDollarMonthly +
      homeownersInsuranceDollarMonthly;
    return sum;
  };

  private calculateMonthlyPayment = ({
    principalAndInterest,
    propertyTaxDollarMonthly,
    hoaDues,
    mortgageInsuranceDollarMonthly,
    homeownersInsuranceDollarMonthly,
  }: {
    principalAndInterest: number;
    propertyTaxDollarMonthly: number;
    hoaDues: number;
    mortgageInsuranceDollarMonthly: number;
    homeownersInsuranceDollarMonthly: number;
  }) => {
    return this.sumMonthlyPayment({
      principalAndInterest,
      propertyTaxDollarMonthly,
      hoaDues,
      mortgageInsuranceDollarMonthly,
      homeownersInsuranceDollarMonthly,
    });
  };

  private calculatePrincipalAndInterest = ({
    principal,
    rate,
    term,
  }: {
    principal: number;
    rate: number;
    term: number;
  }): number => {
    const r = rate / 12 / 100;
    const pow = Math.pow(1 + r, term);
    const temp = (principal * (r * pow)) / (pow - 1);
    return temp;
  };

  private calculateAmortization = ({
    balance,
    payment,
    rate,
    term,
  }: {
    balance: number;
    payment: number;
    rate: number;
    term: number;
  }): Array<Amortization> => {
    const amortizationChart: Array<Amortization> = [];
    let previousBalance = balance;

    for (let i = 0; i < term; i++) {
      const r = rate / 100 / 12;
      const interest = r * previousBalance;
      const principal = payment - interest;
      const balance = previousBalance - principal;
      const amortization: Amortization = {
        payment: i + 1,
        amount: payment,
        interest: interest,
        principal: principal,
        balance: balance,
      };
      amortizationChart.push(amortization);

      previousBalance = balance;
    }
    return amortizationChart;
  };

  public updateMonthlyPayment = () => {
    const {
      loanAmount,
      interestRate,
      loanTermMonth,
      propertyTaxDollarMonthly,
      hoaDues,
      mortgageInsuranceDollarMonthly,
      homeownersInsuranceDollarMonthly,
    } = this.state;
    const principalAndInterest = this.calculatePrincipalAndInterest({
      principal: loanAmount,
      rate: interestRate,
      term: loanTermMonth,
    });
    const monthlyPayment = this.calculateMonthlyPayment({
      principalAndInterest,
      propertyTaxDollarMonthly,
      hoaDues,
      mortgageInsuranceDollarMonthly,
      homeownersInsuranceDollarMonthly,
    });
    const amortChart = this.calculateAmortization({
      balance: loanAmount,
      payment: principalAndInterest,
      rate: interestRate,
      term: loanTermMonth,
    });

    // this.calculateDonut([
    //     principalAndInterest.toFixed(0),
    //     this.state.propertyTaxDollarMonthly.toFixed(0),
    //     this.state.homeownersInsuranceDollarMonthly.toFixed(0),
    //     this.state.mortgageInsuranceDollarMonthly.toFixed(0),
    //     this.state.hoaDues.toFixed(0)
    // ]);

    this.setState({
      ...this.state,
      principalAndInterest: principalAndInterest,
      monthlyPayment: monthlyPayment,
      amortizationChart: amortChart,
    });
  };

  public handleFieldChanged = (
    evt: React.SyntheticEvent,
    fieldName: string
  ): void => {
    const target = evt.target as HTMLInputElement;

    // if(!validateCurrency({value: target.value})) return;

    const newState = { ...this.state };
    newState[fieldName] = +target.value;

    switch (fieldName) {
      case 'purchasePrice':
        newState['downPaymentDollar'] =
          +target.value * (newState.downPaymentPercent / 100);
        newState['loanAmount'] =
          newState.purchasePrice - newState.downPaymentDollar;
        newState['propertyTaxDollar'] =
          +target.value * (newState.propertyTaxPercent / 100);
        newState['propertyTaxDollarMonthly'] =
          (+target.value * (newState.propertyTaxPercent / 100)) / 12;
        newState['mortgageInsuranceDollarMonthly'] =
          (+target.value * (newState.mortgageInsurancePercent / 100)) / 12;
        newState['homeownersInsuranceDollarMonthly'] =
          (+target.value * (newState.homeownersInsurancePercent / 100)) / 12;
        break;
      case 'downPaymentPercent':
        newState['downPaymentDollar'] =
          newState.purchasePrice * (+target.value / 100);
        newState['loanAmount'] =
          newState.purchasePrice - newState.downPaymentDollar;
        break;
      case 'downPaymentDollar':
        newState['downPaymentPercent'] =
          (+target.value / newState.purchasePrice) * 100;
        newState['loanAmount'] =
          newState.purchasePrice - newState.downPaymentDollar;
        break;
      case 'propertyTaxPercent':
        newState['propertyTaxDollar'] =
          newState.purchasePrice * (+target.value / 100);
        newState['propertyTaxDollarMonthly'] =
          (newState.purchasePrice * (+target.value / 100)) / 12;
        break;
      case 'propertyTaxDollar':
        newState['propertyTaxPercent'] =
          (+target.value / newState.purchasePrice) * 100;
        break;
      case 'mortgageInsurancePercent':
        newState['mortgageInsuranceDollarMonthly'] =
          (newState.purchasePrice * (+target.value / 100)) / 12;
        break;
      case 'mortgageInsuranceDollarMonthly':
        newState['mortgageInsurancePercent'] =
          (+target.value / newState.purchasePrice) * 100 * 12;
        break;
      case 'homeownersInsurancePercent':
        newState['homeownersInsuranceDollarMonthly'] =
          (newState.purchasePrice * (+target.value / 100)) / 12;
        break;
      case 'homeownersInsuranceDollar':
        newState['homeownersInsurancePercent'] =
          (+target.value / newState.purchasePrice) * 100 * 12;
        break;
      case 'loanTermMonth':
        newState['loanTermYear'] = +target.value / 12;
        break;
      case 'loanTermYear':
        newState['loanTermMonth'] = +target.value * 12;
        break;
    }
    newState['principalAndInterest'] = this.calculatePrincipalAndInterest({
      principal: newState.loanAmount,
      rate: newState.interestRate,
      term: newState.loanTermMonth,
    });
    newState['monthlyPayment'] = this.calculateMonthlyPayment({
      principalAndInterest: newState['principalAndInterest'],
      propertyTaxDollarMonthly: newState['propertyTaxDollarMonthly'],
      hoaDues: newState['hoaDues'],
      mortgageInsuranceDollarMonthly:
        newState['mortgageInsuranceDollarMonthly'],
      homeownersInsuranceDollarMonthly:
        newState['homeownersInsuranceDollarMonthly'],
    });

    // this.calculateDonut([
    //     newState.principalAndInterest.toFixed(0),
    //     newState.propertyTaxDollarMonthly.toFixed(0),
    //     newState.homeownersInsuranceDollarMonthly.toFixed(0),
    //     newState.mortgageInsuranceDollarMonthly.toFixed(0),
    //     newState.hoaDues.toFixed(0)
    // ])
    this.setState({ ...newState });
  };

  public componentDidMount = () => {
    this.updateMonthlyPayment();

    this.props.onrender();
  };

  public componentWillUnmount = () => {
    this.props.ondestroy();
  };

  public handleViewChanged = (view: string) => {
    this.setState({ visibleView: view });
  };

  public getChartData() {
    const {
      principalAndInterest,
      propertyTaxDollarMonthly,
      homeownersInsuranceDollarMonthly,
      mortgageInsuranceDollarMonthly,
      hoaDues,
    } = this.state;

    let arr = [
      principalAndInterest.toFixed(0),
      propertyTaxDollarMonthly.toFixed(0),
      homeownersInsuranceDollarMonthly.toFixed(0),
      mortgageInsuranceDollarMonthly.toFixed(0),
      hoaDues.toFixed(0),
    ];

    return arr;
  }
  render() {
    const { amortizationChart, visibleView } = this.state;

    const calculatorView =
      visibleView === CALCULATOR_VIEW ? (
        <div className={classes.MortgageCalculator}>
          <div className={classes.ResultBlock}>
            <DonutChart
              chartData={this.getChartData()}
              primaryDisplay={{
                label: 'Estimated Monthly Payment',
                value: formatCurrency(this.state.monthlyPayment.toFixed(0)),
              }}
              legend={[
                {
                  label: 'Principal & Interest',
                  value: formatCurrency(
                    this.state.principalAndInterest.toFixed(0)
                  ),
                },
                {
                  label: 'Property Taxes',
                  value: formatCurrency(
                    this.state.propertyTaxDollarMonthly.toFixed(0)
                  ),
                },
                {
                  label: 'Homeowners Insurance',
                  value: formatCurrency(
                    this.state.homeownersInsuranceDollarMonthly.toFixed(0)
                  ),
                },
                {
                  label: 'Mortgage Insurance',
                  value: formatCurrency(
                    this.state.mortgageInsuranceDollarMonthly.toFixed(0)
                  ),
                },
                {
                  label: 'HOA',
                  value: formatCurrency(this.state.hoaDues.toFixed(0)),
                },
              ]}
            />
          </div>

          <form className={classes.Form}>
            <div className={`${classes.FormControl} + ${gridClasses.Flex50}`}>
              <label>Purchase Price ($)</label>
              <input
                type="text"
                value={this.state.purchasePrice}
                onChange={(evt: React.SyntheticEvent) =>
                  this.handleFieldChanged(evt, 'purchasePrice')
                }
              />
            </div>

            <div className={`${classes.FormControl} + ${gridClasses.Flex50}`}>
              <label>
                Down Payment(%) {formatCurrency(this.state.downPaymentDollar)}
              </label>
              <input
                type="text"
                value={this.state.downPaymentPercent}
                onChange={(evt: React.SyntheticEvent) =>
                  this.handleFieldChanged(evt, 'downPaymentPercent')
                }
              />
            </div>

            <div className={`${classes.FormControl} + ${gridClasses.Flex50}`}>
              <label>
                Property Tax (%) {formatCurrency(this.state.propertyTaxDollar)}
                /yr
              </label>
              <input
                type="text"
                value={this.state.propertyTaxPercent}
                onChange={(evt: React.SyntheticEvent) =>
                  this.handleFieldChanged(evt, 'propertyTaxPercent')
                }
              />
            </div>

            <div className={`${classes.FormControl} + ${gridClasses.Flex50}`}>
              <label>HOA ($)</label>
              <input
                type="text"
                value={this.state.hoaDues}
                onChange={(evt: React.SyntheticEvent) =>
                  this.handleFieldChanged(evt, 'hoaDues')
                }
              />
            </div>

            <div className={`${classes.FormControl} + ${gridClasses.Flex50}`}>
              <label>
                Mortgage Insurance (%){' '}
                {formatCurrency(this.state.mortgageInsuranceDollarMonthly)}/mo
              </label>
              <input
                type="text"
                value={this.state.mortgageInsurancePercent}
                onChange={(evt: React.SyntheticEvent) =>
                  this.handleFieldChanged(evt, 'mortgageInsurancePercent')
                }
              />
            </div>

            <div className={`${classes.FormControl} + ${gridClasses.Flex50}`}>
              <label>
                Homeowners Insurance (%){' '}
                {formatCurrency(this.state.homeownersInsuranceDollarMonthly)}/mo
              </label>
              <input
                type="text"
                value={this.state.homeownersInsurancePercent}
                onChange={(evt: React.SyntheticEvent) =>
                  this.handleFieldChanged(evt, 'homeownersInsurancePercent')
                }
              />
            </div>

            <div className={`${classes.FormControl} + ${gridClasses.Flex50}`}>
              <label>Interest Rate (%)</label>
              <input
                type="text"
                value={this.state.interestRate}
                onChange={(evt: React.SyntheticEvent) =>
                  this.handleFieldChanged(evt, 'interestRate')
                }
              />
            </div>

            <div className={`${classes.FormControl} + ${gridClasses.Flex50}`}>
              <label>Loan Term (years)</label>
              <input
                type="text"
                value={this.state.loanTermYear}
                onChange={(evt: React.SyntheticEvent) =>
                  this.handleFieldChanged(evt, 'loanTermYear')
                }
              />
            </div>

            <div className={classes.CalculatorActions}>
              <button
                className={classes.Button}
                onClick={() => this.handleViewChanged(AMORTIZATION_VIEW)}
              >
                Amortization Chart
              </button>
            </div>
          </form>
        </div>
      ) : null;

    const amortizationView =
      visibleView === AMORTIZATION_VIEW ? (
        <div className={classes.AmortizationSchedule}>
          <button
            onClick={() => this.handleViewChanged(CALCULATOR_VIEW)}
            className={classes.Button}
          >
            Calculator
          </button>
          <div className={gridClasses.Grid}>
            <div className={gridClasses.TableHeader}>
              <div className={gridClasses.TableHeaderCell}>Payment</div>
              <div className={gridClasses.TableHeaderCell}>Amount</div>
              <div className={gridClasses.TableHeaderCell}>Interest</div>
              <div className={gridClasses.TableHeaderCell}>Principal</div>
              <div className={gridClasses.TableHeaderCell}>Balance</div>
            </div>
            <div className={gridClasses.TableBody}>
              {amortizationChart.map((amort) => {
                return (
                  <div className={gridClasses.TableRow} key={amort.payment}>
                    <div className={gridClasses.TableCell}>{amort.payment}</div>
                    <div className={gridClasses.TableCell}>
                      <CurrencyFormat
                        value={amort.amount.toFixed(0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </div>
                    <div className={gridClasses.TableCell}>
                      <CurrencyFormat
                        value={amort.interest.toFixed(0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </div>
                    <div className={gridClasses.TableCell}>
                      <CurrencyFormat
                        value={amort.principal.toFixed(0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </div>
                    <div className={gridClasses.TableCell}>
                      <CurrencyFormat
                        value={amort.balance.toFixed(0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null;

    return (
      <div>
        {calculatorView}
        {amortizationView}
      </div>
    );
  }
}

export default MortgageCalculator;
