import { CalculatorField, TuroFieldKey } from './useTuroFields';

export const groupBy = <Type>(
  items: Type[],
  key: string
): Record<string, Type[]> => {
  return items.reduce((acc, item) => {
    if (!acc[item[key]]) {
      acc[item[key]] = [];
    }
    acc[item[key]].push(item);
    return acc;
  }, {});
};

export const getFieldByKey = (
  fields: CalculatorField[],
  key: TuroFieldKey
): CalculatorField =>
  fields.find((field) => field.key === key) as CalculatorField;

export const noDivideByZero = (val: number, fallback?: number) =>
  val || fallback || 1;

export const ensureValidValue = (val: any, field: CalculatorField) => {
  let safeVal = val;
  const zeroFields = [
    'interestRate',
    'term',
    'numDays',
    'dailyRate',
    'insurance',
    'cleaning',
    'turoFee',
    'fuel',
    'registration',
    'maintenance',
  ];
  if (zeroFields.includes(field.key)) {
    safeVal = val || 0;
  }
  return safeVal;
};
