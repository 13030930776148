import React, { useState } from 'react';
import { Form } from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { Box, Button, Grid } from '@mui/material';

const schema: RJSFSchema = {
  type: 'object',
  required: ['email', 'password'],
  properties: {
    email: { type: 'string', title: 'Email', format: 'email' },
    password: { type: 'string', title: 'Password', format: 'password' },
  },
};
const log = (type) => console.log.bind(console, type);
export const WelcomePage = (props: { onSignin: () => void }) => {
  const auth = useAuth();
  const [firebaseError, setFirebaseError] = useState('');
  const signin = async (creds) => {
    try {
      const user = await auth.signIn(creds);
      console.log('auth: ', auth);
      setFirebaseError('');
      props.onSignin();
    } catch (e: any) {
      if (e.code == 'auth/invalid-email') {
        setFirebaseError('Invalid Email');
      } else if (
        e.code == 'auth/user-not-found' ||
        e.code === 'auth/wrong-password'
      ) {
        setFirebaseError(
          'Username or password are not valid. If you have forgotten your password reset it below.'
        );
      } else {
        setFirebaseError(`Unknown error occurred: ${e.code}`);
      }
    }
  };
  const handleSigninSubmit = (form) => {
    log('submitted');
    signin(form.formData);
  };
  return (
    <Box>
      <h1>Welcome to Digatools!</h1>
      <p>Sell our Calculators for Profit!</p>
      <Form schema={schema} validator={validator} onSubmit={handleSigninSubmit}>
        <Grid>
          <Button type="submit" variant="contained" fullWidth size="large">
            Sign In
          </Button>
        </Grid>
      </Form>
      <p>
        Don't have an account? <Link to="/signup">Register Now!</Link>
      </p>
      <p>
        Forgot your password? <Link to="/reset">Reset it here</Link>
      </p>
      <div style={{ color: 'red' }}>{firebaseError}</div>
    </Box>
  );
};
