import React, { useState } from 'react';
import { Credentials, useAuth } from '../Login/AuthProvider';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { Button, CircularProgress, FormControl, Box } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';

import { usePurchaseProduct } from '../products/useProducts';
import { HOSTNAME } from '../../index';
import { StyledStripeInput, StyledStripeLabel } from './CheckoutForm.styles';

const StyledBox = styled(Box)`
  flex-grow: 1;
  overflow: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
`;
const StyledFormFields = styled(FormControl)`
  width: 300px;
`;
const createStripeCustomer = async ({ credentials, userId }) => {
  return fetch(`${HOSTNAME}/api/create-stripe-customer`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      credentials,
      userId,
    }),
  })
    .then((res) => res.json())
    .then(({ customer }) => {
      return customer;
    })
    .catch((e) => {
      console.error('error: ', e);
    });
};
export const CheckoutForm = ({
  productId,
  referrerUserId,
  paymentIntent,
  returnUrl,
}: {
  productId: string;
  referrerUserId: string;
  paymentIntent: { id: string; clientSecret: string };
  returnUrl: string;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { signUp } = useAuth();
  const purchaseProduct = usePurchaseProduct();
  const [alreadyPurchased, setAlreadyPurchased] =
    React.useState<boolean>(false);
  const [firebaseError, setFirebaseError] = React.useState('');
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = React.useState<Credentials>({
    name: '',
    password: '',
    email: '',
  });
  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }

  //   // const clientSecret = new URLSearchParams(window.location.search).get(
  //   //   'payment_intent_client_secret'
  //   // );

  //   if (!clientSecret) {
  //     return;
  //   }

  //   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //     switch (paymentIntent?.status) {
  //       case 'succeeded':
  //         setMessage('Payment succeeded!');
  //         break;
  //       case 'processing':
  //         setMessage('Your payment is processing.');
  //         break;
  //       case 'requires_payment_method':
  //         setMessage('Your payment was not successful, please try again.');
  //         break;
  //       default:
  //         setMessage('Something went wrong.');
  //         break;
  //     }
  //   });
  // }, [stripe]);

  const onChange = (key: keyof Credentials) => (evt: React.SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    const value = target.value;
    setCredentials({
      ...credentials,
      [key]: value,
    });
  };

  const handleConfirmStripePayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // TODO(JK): Handle api error when returnUrl not defined
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl,
        receipt_email: credentials.email,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message || '');
    } else {
      setMessage('An unexpected error occurred.');
    }
    return true;
  };
  const onSignup = async () => {
    // Create firebase user
    // First check if user is auth'd then display "Hi user with email"
    // If user is not auth'd then allow them to enter email. Try to signup. If signup fails prompt email already in use, login.
    // If firebase user is admin AND its their product grant access
    try {
      const user = await signUp(
        { ...credentials, password: Math.random().toFixed(6) },
        false
      );
      setFirebaseError('');

      if (!user?.user.uid) {
        throw new Error('[Missing - userId]: Failed to create user');
      }
      return user;
    } catch (e: any) {
      if (e.code == 'auth/invalid-email') {
        setFirebaseError('Invalid Email');
      } else if (e.code === 'auth/weak-password') {
        setFirebaseError('Weak password. Please enter a stronger password.');
      } else if (e.code == 'auth/email-already-in-use') {
        setFirebaseError('Email already in use. Forgot password?');
      } else {
        setFirebaseError(`Unknown error occurred: ${e.code}`);
      }
      return;
    }
  };
  const handlePurchaseFlow = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      throw new Error(
        '[Missing - stripe | elements]: No productId was provided'
      );
    }
    if (!productId) {
      throw new Error('[Missing - productId]: No productId was provided');
    }
    if (!referrerUserId) {
      throw new Error(
        '[Missing - referrerUserId]: No referrerUserId was provided'
      );
    }
    setIsLoading(true);

    // Create firebase user
    let firebaseUser;
    try {
      firebaseUser = await onSignup();
    } catch (err) {
      console.log('err: ', err);
    }

    // Create stripe customer
    const customer = await createStripeCustomer({
      credentials,
      userId: firebaseUser?.user.uid,
    });

    // Create product transaction (userId, userEmail, stripeCustomerId, productId, affiliateId)
    await purchaseProduct({
      userId: firebaseUser?.user.uid || '',
      userEmail: firebaseUser?.user.email || '',
      productId,
      transactionStatus: 'pending',
      stripePaymentIntentId: paymentIntent.id,
      stripeCustomerId: customer.id,
      referrerUserId,
    });

    // User purchases product from stripe
    await handleConfirmStripePayment();

    setIsLoading(false);

    // setHasPaid(true);
  };

  const handleSendRecoveryEmail = () => {
    // sendPasswordResetEmail();
  };
  return (
    <StyledBox
      component="form"
      autoComplete="off"
      onSubmit={async (event) => {
        if (!alreadyPurchased) {
          handlePurchaseFlow();
        } else {
          handleSendRecoveryEmail();
        }
        event.preventDefault();
        return true;
      }}
    >
      <StyledFormFields>
        {!alreadyPurchased && (
          <div>
            <StyledStripeLabel>Name</StyledStripeLabel>
            <StyledStripeInput
              placeholder="John Doe"
              value={credentials.name}
              onChange={onChange('name')}
              disabled={isLoading}
              required
            />
          </div>
        )}
        <div>
          <StyledStripeLabel>Email</StyledStripeLabel>
          <StyledStripeInput
            placeholder="johndoe@gmail.com"
            required
            type="email"
            disabled={isLoading}
            value={credentials.email}
            onChange={onChange('email')}
            error
          />
        </div>
        {!alreadyPurchased && <PaymentElement id="payment-element" />}
        {!alreadyPurchased && (
          <Button
            style={{ marginTop: '24px', width: '100%' }}
            type="submit"
            variant="outlined"
            disabled={
              isLoading ||
              !stripe ||
              !elements ||
              !credentials.name ||
              !credentials.email
            }
          >
            {isLoading && <CircularProgress size={20} />}
            Purchase ($9.99)
          </Button>
        )}
        {alreadyPurchased && (
          <Button
            style={{ marginTop: '24px', width: '100%' }}
            type="submit"
            variant="outlined"
            disabled={!credentials.email}
          >
            {isLoading && <CircularProgress size={20} />}
            Send recovery email
          </Button>
        )}
        <div style={{ color: 'red' }}>{firebaseError}</div>
        <Button
          style={{ marginTop: '24px', width: '100%' }}
          type="submit"
          variant="text"
          onClick={() => {
            setFirebaseError('');
            setAlreadyPurchased(!alreadyPurchased);
          }}
          disabled={isLoading}
        >
          {alreadyPurchased ? 'Buy Now' : `Already purchased?`}
        </Button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </StyledFormFields>
    </StyledBox>
  );
};
