import styled from 'styled-components';

export const StyledStripeInput = styled.input`
  margin-bottom: 12px !important;
  --p-outerFrameHeightTransitionDuration: 0.35s;
  --p-outerFrameHeightIncreaseTransitionDelay: 0.02s;
  --colorPrimaryText: #fff;
  --p-colorPrimaryHover: #0b81fa;
  --p-colorPrimaryActive: #228cfa;
  --p-colorPrimaryAlpha20: hsla(210, 96%, 45%, 25%);
  --p-colorPrimaryAlpha40: hsla(210, 96%, 45%, 40%);
  --p-colorPrimaryAlpha50: hsla(210, 96%, 45%, 50%);
  --p-colorPrimaryDisabled: #d9d9d9;
  --p-colorPrimaryDisabledText: #8d8d8d;
  --colorBackgroundText: #000000;
  --p-colorBackgroundDivider: #f2f2f2;
  --p-colorBackgroundDisabled: #f2f2f2;
  --p-colorBackgroundDisabledDeemphasize05: #e6e6e6;
  --p-colorBackgroundDeemphasize03: #f7f7f7;
  --p-colorBackgroundDeemphasize05: #f2f2f2;
  --p-colorBackgroundDeemphasize10: #e6e6e6;
  --p-colorBackgroundDeemphasize15: #d9d9d9;
  --p-colorBackgroundDeemphasize20: #cccccc;
  --p-colorBackgroundLightenAbsolute05: #ffffff;
  --p-colorBackgroundContrastAlpha05: rgba(0, 0, 0, 0.05);
  --p-colorBackgroundContrastAlpha08: rgba(0, 0, 0, 0.08);
  --p-colorBackgroundContrastAlpha30: rgba(0, 0, 0, 0.3);
  --colorTextSecondary: #4d4d4d;
  --colorTextPlaceholder: #757575;
  --colorSuccessText: #fff;
  --colorDangerText: #fff;
  --colorWarningText: #000000;
  --fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --fontSmooth: always;
  --fontVariantLigatures: normal;
  --fontVariationSettings: normal;
  --fontLineHeight: 1.45;
  --fontSizeBase: 1rem;
  --fontSizeSm: 0.93rem;
  --fontSizeXs: 0.875rem;
  --fontSize2Xs: 0.8125rem;
  --fontSize3Xs: 0.75rem;
  --fontSizeLg: 1.0625rem;
  --fontSizeXl: 1.125rem;
  --fontSize2Xl: 1.25rem;
  --fontWeightLight: 300;
  --fontWeightNormal: 400;
  --fontWeightMedium: 600;
  --fontWeightBold: bold;
  --colorPrimary: #0570de;
  --colorBackground: #fff;
  --colorText: #000000;
  --colorSuccess: #30b130;
  --colorDanger: #df1b41;
  --colorWarning: #f6e6b9;
  --colorIcon: var(--colorTextSecondary);
  --colorIconHover: var(--colorText);
  --colorIconCheckmark: var(--colorPrimaryText);
  --colorIconCardError: var(--colorDanger);
  --colorIconCardCvc: var(--colorIcon);
  --colorIconCardCvcError: var(--colorDanger);
  --colorIconChevronDown: var(--colorIcon);
  --colorIconChevronDownHover: var(--colorIconHover);
  --colorIconClose: var(--colorIcon);
  --colorIconCloseHover: var(--colorIconHover);
  --colorIconLoadingIndicator: var(--p-colorBackgroundContrastAlpha30);
  --colorIconMenu: var(--colorPrimary);
  --colorIconMenuHover: var(--colorIconMenu);
  --colorIconMenuOpen: var(--colorIconMenu);
  --colorIconPasscodeDevice: var(--colorIcon);
  --colorIconPasscodeDeviceHover: var(--colorIconHover);
  --colorIconPasscodeDeviceNotification: var(--colorPrimary);
  --colorIconRedirect: currentColor;
  --colorIconTab: var(--colorIcon);
  --colorIconTabHover: var(--colorText);
  --colorIconTabSelected: var(--colorPrimary);
  --colorIconTabMore: var(--colorIcon);
  --colorIconTabMoreHover: var(--colorText);
  --spacingUnit: 0.25rem;
  --spacingGridRow: var(--p-spacing3);
  --spacingGridColumn: var(--p-spacing3);
  --spacingTab: var(--p-spacing1);
  --spacingPickerItem: var(--p-spacing2);
  --spacingAccordionItem: var(--p-spacing2);
  --borderRadius: 5px;
  --focusBoxShadow: 0 0 0 3px var(--p-colorPrimaryAlpha20),
    0 1px 1px 0 var(--p-colorBackgroundContrastAlpha08);
  --focusOutline: 0;
  --p-spacingXs: 1px;
  --p-spacingSm: 2px;
  --p-spacing1: 0.25rem;
  --p-spacing2: 0.5rem;
  --p-spacing3: 0.75rem;
  --p-spacing4: 1rem;
  --p-spacing5: 1.25rem;
  --p-spacing6: 1.5rem;
  --p-spacing7: 1.75rem;
  --p-spacing8: 2rem;
  --p-spacing9: 2.25rem;
  --p-spacing10: 2.5rem;
  --p-logoLightDisplay: block;
  --p-logoDarkDisplay: none;
  --p-logoTabLightDisplay: block;
  --p-logoTabDarkDisplay: none;
  --p-logoTabSelectedLightDisplay: block;
  --p-logoTabSelectedDarkDisplay: none;
  --p-logoBlockLightDisplay: block;
  --p-logoBlockDarkDisplay: none;
  -webkit-font-smoothing: antialiased;
  --c-inputPaddingTop: 12px;
  --c-inputPaddingRight: 12px;
  --c-inputPaddingBottom: 12px;
  --c-inputPaddingLeft: 12px;
  --c-inputBorderTopWidth: 1px;
  --c-inputBorderRightWidth: 1px;
  --c-inputBorderBottomWidth: 1px;
  --c-inputBorderLeftWidth: 1px;
  --c-inputDividerWidth: 1px;
  --c-inputDividerBackgroundColor: rgb(230, 230, 230);
  --c-inputBorderTopLeftRadius: 5px;
  --c-inputBorderTopRightRadius: 5px;
  --c-inputBorderBottomRightRadius: 5px;
  --c-inputBorderBottomLeftRadius: 5px;
  --c-inputFontSize: 16px;
  --c-inputFontColor: rgb(0, 0, 0);
  --c-blockPaddingTop: 12px;
  --c-blockPaddingRight: 12px;
  --c-blockPaddingBottom: 12px;
  --c-blockPaddingLeft: 12px;
  --c-blockBackgroundColor: rgb(255, 255, 255);
  --c-blockDividerWidth: 1px;
  --c-blockDividerBackgroundColor: rgb(230, 230, 230);
  --c-dropdownDividerWidth: 1px;
  --c-dropdownDividerBackgroundColor: rgb(230, 230, 230);
  --c-dropdownItemBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemPaddingTop: 12px;
  --c-pickerItemPaddingRight: 12px;
  --c-pickerItemPaddingBottom: 12px;
  --c-pickerItemPaddingLeft: 12px;
  --c-pickerItemBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemHoveredBackgroundColor: rgb(247, 247, 247);
  --c-pickerItemFocusedBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemSelectedBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemSelectedHoveredBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemHighlightBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemHighlightHoveredBackgroundColor: rgb(247, 247, 247);
  --c-pickerItemHighlightFocusedBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemDisabledBackgroundColor: rgb(230, 230, 230);
  --c-pickerItemDisabledHoveredBackgroundColor: rgb(230, 230, 230);
  --c-pickerItemDisabledFocusedBackgroundColor: rgb(230, 230, 230);
  --c-pickerItemTransitionDelay: 0s, 0s, 0s, 0s;
  --c-pickerItemTransitionDuration: 0.15s, 0.15s, 0.15s, 0.15s;
  --c-pickerItemTransitionProperty: background, box-shadow, border, color;
  --c-pickerItemTransitionTimingFunction: ease, ease, ease, ease;
  --c-actionPaddingTop: 4px;
  --c-actionPaddingRight: 6px;
  --c-actionPaddingBottom: 4px;
  --c-actionPaddingLeft: 6px;
  --c-labelRestingFontSize: 16px;
  --c-labelFloatingHeight: 20.0938px;
  --c-labelFloatingTransform: translateY(13px) scale(0.8888888888888888);
  --c-labelRestingTransform: translateY(23px) scale(1);
  --c-inputFloatingPaddingTop: 32px;
  --c-inputFloatingMarginLeft: 13px;
  --c-inputFloatingMarginRight: 13px;
  --c-acItemPaddingTop: 16px;
  --c-acItemPaddingRight: 16px;
  --c-acItemPaddingBottom: 16px;
  --c-acItemPaddingLeft: 16px;
  --c-acItemBorderTopWidth: 1px;
  --c-acItemBorderRightWidth: 1px;
  --c-acItemBorderBottomWidth: 1px;
  --c-acItemBorderLeftWidth: 1px;
  --c-acItemBorderWidth: 1px;
  --c-acItemBorderColor: rgb(230, 230, 230);
  --c-acItemBorderStyle: solid;
  --c-acItemBorderRadius: 5px;
  --c-acItemBorderTopLeftRadius: 5px;
  --c-acItemBorderTopRightRadius: 5px;
  --c-acItemBorderBottomRightRadius: 5px;
  --c-acItemBorderBottomLeftRadius: 5px;
  --c-acItemBackgroundColor: rgb(255, 255, 255);
  --c-acItemColor: rgb(77, 77, 77);
  --c-acBoxShadow: rgba(0, 0, 0, 0.03) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.02) 0px 3px 6px 0px;
  --c-acOutline: rgb(77, 77, 77) none 0px;
  --c-acItemFont: 600 14px / 16.1px -apple-system, 'system-ui', 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --c-acItemHoveredBackgroundColor: rgb(255, 255, 255);
  --c-acItemHoveredColor: rgb(0, 0, 0);
  --c-acItemHoveredOutline: rgb(0, 0, 0) none 0px;
  --c-acItemHoveredBorderColor: rgb(230, 230, 230);
  --c-acItemFocusedBackgroundColor: rgb(255, 255, 255);
  --c-acItemFocusedColor: rgb(77, 77, 77);
  --c-acItemFocusedOutline: rgb(77, 77, 77) none 0px;
  --c-acItemFocusedBoxShadow: rgba(0, 0, 0, 0.03) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.02) 0px 3px 6px 0px, rgba(5, 115, 225, 0.25) 0px 0px 0px 3px,
    rgba(0, 0, 0, 0.08) 0px 1px 1px 0px;
  --c-acItemFocusedBorderColor: rgba(5, 115, 225, 0.5);
  --c-acItemSelectedBackgroundColor: rgb(255, 255, 255);
  --c-acItemSelectedColor: rgb(5, 112, 222);
  --c-acItemSelectedBorderColor: rgb(230, 230, 230);
  --c-acItemSelectedHoveredBackgroundColor: rgb(255, 255, 255);
  --c-acItemSelectedHoveredBorderColor: rgb(230, 230, 230);
  --c-acItemSelectedHoveredColor: rgb(5, 112, 222);
  --p-pickerItemBackgroundColorDeemphasize03: rgb(247, 247, 247);
  --p-pickerItemBackgroundColorDeemphasize05: rgb(242, 242, 242);
  --p-fadeEnterDuration: 0.4s;
  --p-fadeEnterDelay: 0s;
  --p-fadeExitDuration: 0.2s;
  --p-fadeExitDelay: 0s;
  box-sizing: border-box;
  margin: 0;
  appearance: none;
  filter: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  vertical-align: middle;
  color: inherit;
  display: block;
  width: 100%;
  padding: var(--p-spacing3);
  background-color: var(--colorBackground);
  border-radius: var(--borderRadius);
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
`;

export const StyledStripeLabel = styled.label`
  color: var(--colorText);
  font-family: var(--fontFamily);
  line-height: 1.15;
  --p-outerFrameHeightTransitionDuration: 0.35s;
  --p-outerFrameHeightIncreaseTransitionDelay: 0.02s;
  --colorPrimaryText: #fff;
  --p-colorPrimaryHover: #0b81fa;
  --p-colorPrimaryActive: #228cfa;
  --p-colorPrimaryAlpha20: hsla(210, 96%, 45%, 25%);
  --p-colorPrimaryAlpha40: hsla(210, 96%, 45%, 40%);
  --p-colorPrimaryAlpha50: hsla(210, 96%, 45%, 50%);
  --p-colorPrimaryDisabled: #d9d9d9;
  --p-colorPrimaryDisabledText: #8d8d8d;
  --colorBackgroundText: #000000;
  --p-colorBackgroundDivider: #f2f2f2;
  --p-colorBackgroundDisabled: #f2f2f2;
  --p-colorBackgroundDisabledDeemphasize05: #e6e6e6;
  --p-colorBackgroundDeemphasize03: #f7f7f7;
  --p-colorBackgroundDeemphasize05: #f2f2f2;
  --p-colorBackgroundDeemphasize10: #e6e6e6;
  --p-colorBackgroundDeemphasize15: #d9d9d9;
  --p-colorBackgroundDeemphasize20: #cccccc;
  --p-colorBackgroundLightenAbsolute05: #ffffff;
  --p-colorBackgroundContrastAlpha05: rgba(0, 0, 0, 0.05);
  --p-colorBackgroundContrastAlpha08: rgba(0, 0, 0, 0.08);
  --p-colorBackgroundContrastAlpha30: rgba(0, 0, 0, 0.3);
  --colorTextSecondary: #4d4d4d;
  --colorTextPlaceholder: #757575;
  --colorSuccessText: #fff;
  --colorDangerText: #fff;
  --colorWarningText: #000000;
  --fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --fontSmooth: always;
  --fontVariantLigatures: normal;
  --fontVariationSettings: normal;
  --fontLineHeight: 1.45;
  --fontSizeBase: 1rem;
  --fontSizeSm: 0.93rem;
  --fontSizeXs: 0.875rem;
  --fontSize2Xs: 0.8125rem;
  --fontSize3Xs: 0.75rem;
  --fontSizeLg: 1.0625rem;
  --fontSizeXl: 1.125rem;
  --fontSize2Xl: 1.25rem;
  --fontWeightLight: 300;
  --fontWeightNormal: 400;
  --fontWeightMedium: 600;
  --fontWeightBold: bold;
  --colorPrimary: #0570de;
  --colorBackground: #fff;
  --colorText: #000000;
  --colorSuccess: #30b130;
  --colorDanger: #df1b41;
  --colorWarning: #f6e6b9;
  --colorIcon: var(--colorTextSecondary);
  --colorIconHover: var(--colorText);
  --colorIconCheckmark: var(--colorPrimaryText);
  --colorIconCardError: var(--colorDanger);
  --colorIconCardCvc: var(--colorIcon);
  --colorIconCardCvcError: var(--colorDanger);
  --colorIconChevronDown: var(--colorIcon);
  --colorIconChevronDownHover: var(--colorIconHover);
  --colorIconClose: var(--colorIcon);
  --colorIconCloseHover: var(--colorIconHover);
  --colorIconLoadingIndicator: var(--p-colorBackgroundContrastAlpha30);
  --colorIconMenu: var(--colorPrimary);
  --colorIconMenuHover: var(--colorIconMenu);
  --colorIconMenuOpen: var(--colorIconMenu);
  --colorIconPasscodeDevice: var(--colorIcon);
  --colorIconPasscodeDeviceHover: var(--colorIconHover);
  --colorIconPasscodeDeviceNotification: var(--colorPrimary);
  --colorIconRedirect: currentColor;
  --colorIconTab: var(--colorIcon);
  --colorIconTabHover: var(--colorText);
  --colorIconTabSelected: var(--colorPrimary);
  --colorIconTabMore: var(--colorIcon);
  --colorIconTabMoreHover: var(--colorText);
  --spacingUnit: 0.25rem;
  --spacingGridRow: var(--p-spacing3);
  --spacingGridColumn: var(--p-spacing3);
  --spacingTab: var(--p-spacing1);
  --spacingPickerItem: var(--p-spacing2);
  --spacingAccordionItem: var(--p-spacing2);
  --borderRadius: 5px;
  --focusBoxShadow: 0 0 0 3px var(--p-colorPrimaryAlpha20),
    0 1px 1px 0 var(--p-colorBackgroundContrastAlpha08);
  --focusOutline: 0;
  --p-spacingXs: 1px;
  --p-spacingSm: 2px;
  --p-spacing1: 0.25rem;
  --p-spacing2: 0.5rem;
  --p-spacing3: 0.75rem;
  --p-spacing4: 1rem;
  --p-spacing5: 1.25rem;
  --p-spacing6: 1.5rem;
  --p-spacing7: 1.75rem;
  --p-spacing8: 2rem;
  --p-spacing9: 2.25rem;
  --p-spacing10: 2.5rem;
  --p-logoLightDisplay: block;
  --p-logoDarkDisplay: none;
  --p-logoTabLightDisplay: block;
  --p-logoTabDarkDisplay: none;
  --p-logoTabSelectedLightDisplay: block;
  --p-logoTabSelectedDarkDisplay: none;
  --p-logoBlockLightDisplay: block;
  --p-logoBlockDarkDisplay: none;
  -webkit-font-smoothing: antialiased;
  --c-inputPaddingTop: 12px;
  --c-inputPaddingRight: 12px;
  --c-inputPaddingBottom: 12px;
  --c-inputPaddingLeft: 12px;
  --c-inputBorderTopWidth: 1px;
  --c-inputBorderRightWidth: 1px;
  --c-inputBorderBottomWidth: 1px;
  --c-inputBorderLeftWidth: 1px;
  --c-inputDividerWidth: 1px;
  --c-inputDividerBackgroundColor: rgb(230, 230, 230);
  --c-inputBorderTopLeftRadius: 5px;
  --c-inputBorderTopRightRadius: 5px;
  --c-inputBorderBottomRightRadius: 5px;
  --c-inputBorderBottomLeftRadius: 5px;
  --c-inputFontSize: 16px;
  --c-inputFontColor: rgb(0, 0, 0);
  --c-blockPaddingTop: 12px;
  --c-blockPaddingRight: 12px;
  --c-blockPaddingBottom: 12px;
  --c-blockPaddingLeft: 12px;
  --c-blockBackgroundColor: rgb(255, 255, 255);
  --c-blockDividerWidth: 1px;
  --c-blockDividerBackgroundColor: rgb(230, 230, 230);
  --c-dropdownDividerWidth: 1px;
  --c-dropdownDividerBackgroundColor: rgb(230, 230, 230);
  --c-dropdownItemBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemPaddingTop: 12px;
  --c-pickerItemPaddingRight: 12px;
  --c-pickerItemPaddingBottom: 12px;
  --c-pickerItemPaddingLeft: 12px;
  --c-pickerItemBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemHoveredBackgroundColor: rgb(247, 247, 247);
  --c-pickerItemFocusedBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemSelectedBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemSelectedHoveredBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemHighlightBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemHighlightHoveredBackgroundColor: rgb(247, 247, 247);
  --c-pickerItemHighlightFocusedBackgroundColor: rgb(255, 255, 255);
  --c-pickerItemDisabledBackgroundColor: rgb(230, 230, 230);
  --c-pickerItemDisabledHoveredBackgroundColor: rgb(230, 230, 230);
  --c-pickerItemDisabledFocusedBackgroundColor: rgb(230, 230, 230);
  --c-pickerItemTransitionDelay: 0s, 0s, 0s, 0s;
  --c-pickerItemTransitionDuration: 0.15s, 0.15s, 0.15s, 0.15s;
  --c-pickerItemTransitionProperty: background, box-shadow, border, color;
  --c-pickerItemTransitionTimingFunction: ease, ease, ease, ease;
  --c-actionPaddingTop: 4px;
  --c-actionPaddingRight: 6px;
  --c-actionPaddingBottom: 4px;
  --c-actionPaddingLeft: 6px;
  --c-labelRestingFontSize: 16px;
  --c-labelFloatingHeight: 20.0938px;
  --c-labelFloatingTransform: translateY(13px) scale(0.8888888888888888);
  --c-labelRestingTransform: translateY(23px) scale(1);
  --c-inputFloatingPaddingTop: 32px;
  --c-inputFloatingMarginLeft: 13px;
  --c-inputFloatingMarginRight: 13px;
  --c-acItemPaddingTop: 16px;
  --c-acItemPaddingRight: 16px;
  --c-acItemPaddingBottom: 16px;
  --c-acItemPaddingLeft: 16px;
  --c-acItemBorderTopWidth: 1px;
  --c-acItemBorderRightWidth: 1px;
  --c-acItemBorderBottomWidth: 1px;
  --c-acItemBorderLeftWidth: 1px;
  --c-acItemBorderWidth: 1px;
  --c-acItemBorderColor: rgb(230, 230, 230);
  --c-acItemBorderStyle: solid;
  --c-acItemBorderRadius: 5px;
  --c-acItemBorderTopLeftRadius: 5px;
  --c-acItemBorderTopRightRadius: 5px;
  --c-acItemBorderBottomRightRadius: 5px;
  --c-acItemBorderBottomLeftRadius: 5px;
  --c-acItemBackgroundColor: rgb(255, 255, 255);
  --c-acItemColor: rgb(77, 77, 77);
  --c-acBoxShadow: rgba(0, 0, 0, 0.03) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.02) 0px 3px 6px 0px;
  --c-acOutline: rgb(77, 77, 77) none 0px;
  --c-acItemFont: 600 14px / 16.1px -apple-system, 'system-ui', 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --c-acItemHoveredBackgroundColor: rgb(255, 255, 255);
  --c-acItemHoveredColor: rgb(0, 0, 0);
  --c-acItemHoveredOutline: rgb(0, 0, 0) none 0px;
  --c-acItemHoveredBorderColor: rgb(230, 230, 230);
  --c-acItemFocusedBackgroundColor: rgb(255, 255, 255);
  --c-acItemFocusedColor: rgb(77, 77, 77);
  --c-acItemFocusedOutline: rgb(77, 77, 77) none 0px;
  --c-acItemFocusedBoxShadow: rgba(0, 0, 0, 0.03) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.02) 0px 3px 6px 0px, rgba(5, 115, 225, 0.25) 0px 0px 0px 3px,
    rgba(0, 0, 0, 0.08) 0px 1px 1px 0px;
  --c-acItemFocusedBorderColor: rgba(5, 115, 225, 0.5);
  --c-acItemSelectedBackgroundColor: rgb(255, 255, 255);
  --c-acItemSelectedColor: rgb(5, 112, 222);
  --c-acItemSelectedBorderColor: rgb(230, 230, 230);
  --c-acItemSelectedHoveredBackgroundColor: rgb(255, 255, 255);
  --c-acItemSelectedHoveredBorderColor: rgb(230, 230, 230);
  --c-acItemSelectedHoveredColor: rgb(5, 112, 222);
  --p-pickerItemBackgroundColorDeemphasize03: rgb(247, 247, 247);
  --p-pickerItemBackgroundColorDeemphasize05: rgb(242, 242, 242);
  --p-fadeEnterDuration: 0.4s;
  --p-fadeEnterDelay: 0s;
  --p-fadeExitDuration: 0.2s;
  --p-fadeExitDelay: 0s;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: block;
  margin-bottom: var(--p-spacing1);
  font-size: var(--fontSizeSm);
  font-weight: var(--fontWeightNormal);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
`;
