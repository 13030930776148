import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import { Chip } from '@mui/material';
import { useStripePaymentIntentCreate } from '../../hooks/useStripe';
import { stripeKey } from '../../constants';
import { Login } from '../Login/login';

const stripePromise = loadStripe(stripeKey);

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const StyledPageBanner = styled.div`
  justify-content: center;
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  background: #ff0065;
  color: white;
  // box-shadow: 0px 5px 6px -1px rgba(0, 0, 0, 0.75);
  // -webkit-box-shadow: 0px 5px 6px -1px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 0px 5px 6px -1px rgba(0, 0, 0, 0.75);
  z-index: 1;
  text-align: center;
`;

const StyledPageHeader = styled.h1`
  margin: 0 0 12px 0;
`;
const StyledPageSecondary = styled.h3`
  margin: 0;
`;

const StyledPagePrice = styled.div`
  margin: 24px;
`;
interface IProps {}

const StripeWrapper = ({
  product,
  returnUrl,
  uuid,
}: {
  product: { id: string; amount: number };
  returnUrl: string;
  uuid: string;
}) => {
  const {
    error: paymentIntentError,
    data: paymentIntent = { id: '', clientSecret: '' },
  } = useStripePaymentIntentCreate(product);

  const appearance = {
    theme: 'stripe' as 'stripe',
    variables: {
      colorText: '#000000',
    },
  };
  const options = {
    clientSecret: paymentIntent.clientSecret,
    appearance,
  };
  return (
    <>
      {paymentIntent.clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            productId={product.id}
            referrerUserId={uuid}
            paymentIntent={paymentIntent}
            returnUrl={returnUrl}
          />
        </Elements>
      )}
      {paymentIntentError && (
        <div id="error-message">
          {(paymentIntentError as unknown as any).message ||
            'An error occurred'}
        </div>
      )}
    </>
  );
};

export const Paywall: React.FC<IProps> = () => {
  const params = useParams<{ productId: string; referrerId: string }>();
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('return_url');

  const mappedParams = {
    ...params,
  };
  if (!mappedParams.productId) {
    console.error('[productId required] - productId not provided.');
    return <div>error loading product purchase form</div>;
  }
  if (!mappedParams.referrerId) {
    // TODO(JK): Should we require uuid?
    console.error(
      '[referrerId required] - uuid not provided. Using default uuid'
    );
    mappedParams['referrerId'] = 'mUbaxXwIWndJE0H6QVDXjqd0zxW2';
  }
  return (
    <StyledContainer>
      <StyledPageBanner>
        <StyledPageHeader>Turo Profit Calculator</StyledPageHeader>
        <StyledPageSecondary>
          See how much money you could earn!
        </StyledPageSecondary>
        <StyledPagePrice>
          <Chip label="$9.99" color="primary" />
        </StyledPagePrice>
      </StyledPageBanner>
      <div>
        <Login />
        <StripeWrapper
          returnUrl={returnUrl || ''}
          product={{ id: mappedParams.productId, amount: 999 }}
          uuid={mappedParams.referrerId}
        />
      </div>
    </StyledContainer>
  );
};
