import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AppHeader } from './AppHeader';
import { Login } from './pages/Login/login';
import { Signup } from './pages/Login/signup';
import { Products } from './pages/products/products';
import { CustomerProduct } from './pages/customer/customer';
import { AuthRequired } from './pages/Login/AuthProvider';
import { Home } from './pages/Home/home';
import { UserPage } from './pages/Users/users';
import { Paywall } from './pages/Paywall/Paywall';
import { Analytics } from './pages/Analytics/analytics';
import Bugsnag from '@bugsnag/js';
import { ResetPassword } from './pages/Login/resetPassword';

const isDev = process.env.NODE_ENV === 'development';
const queryClient = new QueryClient();

const App = () => {
  React.useEffect(() => {
    Bugsnag.notify(new Error('Test error3'));
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Routes>
          <Route
            path="/client"
            element={
              <AuthRequired>
                <>
                  <AppHeader />
                  <Outlet />
                </>
              </AuthRequired>
            }
          >
            {isDev && (
              <>
                <Route index element={<Home />} />
                <Route path="users" element={<UserPage />} />
              </>
            )}
            <Route path="products" element={<Products />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="*" element={<Navigate to="/client/products" />} />
          </Route>

          <Route path="/shared" element={<Outlet />}>
            <Route path="paywall/:productId" element={<Paywall />} />
            <Route path=":productId/:uuid" element={<CustomerProduct />} />
          </Route>

          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </QueryClientProvider>
  );
};

export default App;
