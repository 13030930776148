import React from 'react';
import classes from './AutoCalculator.module.scss';
import { RJSFSchema } from '@rjsf/utils';
import { Form } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { Button, Grid } from '@mui/material';

interface IProps {
  onrender: any;
  ondestroy: any;
  apikey?: string;
}

interface IState {
  [key: string]: any;
  principal: number;
  interest: number;
  term: number;
  monthlyPayment: number;
}

const schema: RJSFSchema = {
  title: 'Auto Loan Calculator',
  type: 'object',
  required: ['principal', 'interest', 'term'],
  properties: {
    principal: {
      type: 'number',
      title: 'Loan Amount',
    },
    interest: { type: 'number', title: 'Interest Rate' },

    term: {
      type: 'number',
      title: 'Loan Period (Years)',
      //   format: 'email',
    },
  },
};

class AutoCalculator extends React.Component<IProps, IState> {
  public state: IState = {
    principal: 25000,
    interest: 3.11,
    term: 5,
    monthlyPayment: 0,
  };

  private calculateAuto = ({
    principal,
    interest,
    term,
  }: {
    principal: number;
    interest: number;
    term: number;
  }): number => {
    const monthlyInterest = interest / 12 / 100;
    const totalMonths = term * 12;
    const compoundInterest = Math.pow(1 + monthlyInterest, totalMonths);
    return (
      (principal * (monthlyInterest * compoundInterest)) /
      (compoundInterest - 1)
    );
  };

  private log = (type) => console.log.bind(console, type);

  public updateMonthlyPayment = () => {
    const { principal, interest, term } = this.state;

    this.setState({
      ...this.state,
      monthlyPayment: this.calculateAuto({ principal, interest, term }),
    });
  };

  public componentDidMount = () => {
    this.updateMonthlyPayment();
    this.props.onrender();
  };

  public componentWillUnmount = () => {
    this.props.ondestroy();
  };

  public handleChange = (form) => {
    this.setState({ ...form.formData });
  };

  public handleSubmitApplication = (form) => {
    this.updateMonthlyPayment();
  };

  render() {
    return (
      <div className={classes.AutoCalculator}>
        <div className={classes.ResultBlock}>
          <div>
            <h4>Estimated Monthly Payment</h4>

            <h2>${this.state.monthlyPayment.toFixed(0)}</h2>
          </div>
        </div>
        <Form
          schema={schema}
          formData={this.state}
          validator={validator}
          onChange={this.handleChange}
          onSubmit={this.handleSubmitApplication}
          onError={this.log('errors')}
        >
          <Grid container>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" fullWidth size="large">
                Calculate
              </Button>
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }
}

export default AutoCalculator;
