export type TuroFieldKey =
  | 'purchasePrice'
  | 'principal'
  | 'downPayment'
  | 'downPaymentType'
  | 'interestRate'
  | 'term'
  | 'numDays'
  | 'dailyRate'
  | 'insurance'
  | 'turoFee'
  | 'cleaning'
  | 'fuel'
  | 'registration'
  | 'maintenance';
export type CalculatorFieldType =
  | 'text'
  | 'numeric'
  | 'currency'
  | 'percent'
  | 'lov';

export type CalculatorFieldOption = { value: any; label: string };

export type CalculatorField = {
  key: TuroFieldKey;
  label: string;
  value: any;
  type: CalculatorFieldType;
  groupId?: string;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  options?: CalculatorFieldOption[];
};

export const useTuroFields = (): CalculatorField[] => {
  return [
    {
      key: 'purchasePrice',
      label: 'Purchase Price',
      value: 25000,
      type: 'currency',
      groupId: '1',
    },
    {
      key: 'downPayment',
      label: 'Down Payment',
      value: 2500,
      type: 'currency',
      groupId: '1',
    },
    {
      key: 'downPaymentType',
      label: 'Down Payment Type',
      value: 'cash',
      type: 'text',
      groupId: '1',
      hidden: true,
    },
    {
      key: 'principal',
      label: 'Loan Amount',
      value: 22500,
      type: 'currency',
      groupId: '1',
    },
    {
      key: 'interestRate',
      label: 'Interest Rate',
      value: 3.5,
      type: 'lov',
      groupId: '1',
      options: Array.from(Array(81)).map((num, idx) => ({
        label: String(idx / 8) + '%',
        value: idx / 8,
      })),
    },
    {
      key: 'term',
      label: 'Term (Years)',
      type: 'numeric',
      value: 5,
      groupId: '1',
    },
    {
      key: 'numDays',
      label: 'Days Rented',
      type: 'numeric',
      value: 20,
      groupId: '2',
    },
    {
      key: 'dailyRate',
      label: 'Daily Rate',
      type: 'currency',
      value: 100,
      groupId: '2',
    },
    {
      key: 'insurance',
      label: 'Insurance',
      value: 100,
      type: 'currency',
      groupId: '3',
    },
    {
      key: 'cleaning',
      label: 'Cleaning',
      value: 100,
      type: 'currency',
      groupId: '3',
    },
    {
      key: 'turoFee',
      label: 'Turo Fee (%)',
      value: 25.0,
      type: 'percent',
      groupId: '3',
    },
    {
      key: 'fuel',
      label: 'Gas / Charging',
      value: 100,
      type: 'currency',
      groupId: '3',
    },
    {
      key: 'registration',
      label: 'Registration (per month)',
      value: 25,
      type: 'currency',
      groupId: '3',
    },
    {
      key: 'maintenance',
      label: 'Maintenance',
      value: 100,
      type: 'currency',
      groupId: '3',
    },
  ];
};
