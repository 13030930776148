import React from 'react';
import { useParams } from 'react-router-dom';
import { accessLevel } from '../../lib/Widgets/Widgets';
import {
  Product,
  useProduct,
  useProductRenderer,
  useUserProductAccess,
} from '../products/useProducts';

interface IProps {}

interface ProductRendererProps {
  product: Product;
  accessLevel: accessLevel;
}
const ProductRenderer: React.FC<ProductRendererProps> = (props) => {
  const productRenderer = useProductRenderer(
    props.product.id,
    '#test-root',
    props.accessLevel
  );
  React.useEffect(() => {
    productRenderer.render();
  }, []);
  return <div id="test-root">Product renderer</div>;
};

export const CustomerProduct: React.FC<IProps> = () => {
  const params = useParams();
  let accessLevel: accessLevel = 'none';
  const { data: product } = useProduct(params.productId || '');
  console.log;
  const products = useUserProductAccess({
    productId: params.productId,
    referrerUserId: params.uuid,
  });

  if (
    products.data?.length &&
    products.data[0].transactionStatus === 'payment_intent.succeeded'
  ) {
    accessLevel = 'all';
  }
  return (
    <>
      {/* {products.status === 'success' && product && (
      )} */}
      {product && <ProductRenderer product={product} accessLevel={'all'} />}
    </>
  );
};
