import React from 'react';
import { CalculatorField } from './useTuroFields';
import { noDivideByZero, getFieldByKey } from './utils';
import styled from 'styled-components';
import * as CurrencyFormat from 'react-currency-format';
import { accessLevel } from '../../Widgets/Widgets';

const StyledResultBlock = styled.div`
  display: flex;
  background: #ff0065;
  color: white;
  box-shadow: 0px 5px 6px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 5px 6px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 6px -1px rgba(0, 0, 0, 0.75);
  z-index: 1;
`;
const StyledPrimaryDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 40%;
`;

const StyledPrimaryResult = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  text-align: center;
`;

const StyledPriamryValue = styled.div`
  font-size: 32px;
`;

const StyledPrimaryLabel = styled.div`
  font-size: 14px;
`;

const StyledSecondaryDisplay = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSecondaryDisplayRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledResult = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

const StyledResultLabel = styled.div`
  font-size: 10px;
`;

const StyledTrialLabel = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 10px;
  color: black;
`;

interface PurchaseInfo {
  purchasePrice: number;
  principal: number;
  downPayment: number;
  downPaymentType: 'percent' | 'cash';
  interestRate: number;
  term: number;
}

interface ExpenseInfo {
  cleaning: number;
  insurance: number;
  turoFee: number;
  fuel: number;
}

interface IncomeInfo {
  dailyRate: number;
  numDays: number;
}

interface PerformanceInfo {
  monthlyPayment: number;
  monthlyIncome: number;
  monthlyExpenses: number;
  monthlyCashflow: number;
  yearlyCoC: number;
  yearlyRoI: number;
}

const calculateMonthlyPayment = (info: PurchaseInfo): number => {
  const monthlyInterest = info.interestRate / 12 / 100;
  const totalMonths = info.term * 12;
  const compoundInterest = Math.pow(1 + monthlyInterest, totalMonths);
  const pymt =
    (info.principal * (monthlyInterest * compoundInterest)) /
    noDivideByZero(compoundInterest - 1, info.term ? 0.00001 : 2);
  return pymt;
};

const calculateMonthlyExpenses = (
  info: ExpenseInfo,
  income: PerformanceInfo['monthlyIncome'],
  monthlyPayment: PerformanceInfo['monthlyPayment']
): number => {
  return (
    info.cleaning +
    info.insurance +
    info.fuel +
    (info.turoFee / 100) * income +
    monthlyPayment
  );
};

const calculateMonthlyIncome = (info: IncomeInfo): number => {
  return info.dailyRate * info.numDays;
};

const calculateMonthlyCashflow = (
  income: PerformanceInfo['monthlyIncome'],
  expenses: PerformanceInfo['monthlyExpenses']
): number => {
  return income - expenses;
};

const calculateCoC = (
  cashflow: number,
  downPayment: PurchaseInfo['downPayment']
): number => {
  if (!downPayment) {
    return Infinity * cashflow;
  }
  return ((cashflow * 12) / downPayment) * 100;
};

const calculateRoI = (
  cashflow: number,
  purchasePrice: PurchaseInfo['purchasePrice']
): number => {
  if (!purchasePrice) {
    return Infinity * cashflow;
  }
  return ((cashflow * 12) / purchasePrice) * 100;
};

export const ResultBlock: React.FC<{
  fields: CalculatorField[];
  randomId: number;
  accessLevel: accessLevel;
}> = (props) => {
  const [performanceInfo, setPerformanceInfo] = React.useState<PerformanceInfo>(
    {
      monthlyPayment: 0,
      monthlyIncome: 0,
      monthlyExpenses: 0,
      monthlyCashflow: 0,
      yearlyCoC: 0,
      yearlyRoI: 0,
    }
  );

  const updateComputedValues = () => {
    const purchasePrice =
      getFieldByKey(props.fields, 'purchasePrice')?.value || 0;
    const principal = getFieldByKey(props.fields, 'principal')?.value || 0;
    const downPayment = getFieldByKey(props.fields, 'downPayment')?.value || 0;
    const downPaymentType =
      getFieldByKey(props.fields, 'downPaymentType')?.value || 'cash';

    const interestRate =
      getFieldByKey(props.fields, 'interestRate')?.value || 0;
    const term = getFieldByKey(props.fields, 'term')?.value || 0;
    const insurance = getFieldByKey(props.fields, 'insurance')?.value || 0;
    const cleaning = getFieldByKey(props.fields, 'cleaning')?.value || 0;
    const turoFee = getFieldByKey(props.fields, 'turoFee')?.value || 0;
    const fuel = getFieldByKey(props.fields, 'fuel')?.value || 0;
    const dailyRate = getFieldByKey(props.fields, 'dailyRate')?.value || 0;
    const numDays = getFieldByKey(props.fields, 'numDays')?.value || 0;

    const updatedIncomeInfo: IncomeInfo = {
      dailyRate,
      numDays,
    };
    const updatedExpenseInfo: ExpenseInfo = {
      insurance,
      cleaning,
      turoFee,
      fuel,
    };
    const updatedPurchaseInfo: PurchaseInfo = {
      downPaymentType,
      purchasePrice,
      principal,
      downPayment,
      interestRate,
      term,
    };

    const monthlyIncome = calculateMonthlyIncome(updatedIncomeInfo);
    const monthlyPayment = calculateMonthlyPayment(updatedPurchaseInfo);
    const monthlyExpenses = calculateMonthlyExpenses(
      updatedExpenseInfo,
      monthlyIncome,
      monthlyPayment
    );
    const monthlyCashflow = calculateMonthlyCashflow(
      monthlyIncome,
      monthlyExpenses
    );
    const yearlyCoC = calculateCoC(monthlyCashflow, downPayment);
    const yearlyRoI = calculateRoI(monthlyCashflow, purchasePrice);

    setPerformanceInfo({
      ...performanceInfo,
      monthlyPayment,
      monthlyIncome,
      monthlyExpenses,
      monthlyCashflow,
      yearlyCoC,
      yearlyRoI,
    });
  };
  React.useEffect(() => {
    updateComputedValues();
  }, [props.randomId]);
  const isTrial = props.accessLevel !== 'all';
  return (
    <StyledResultBlock>
      {isTrial && <StyledTrialLabel>TRIAL</StyledTrialLabel>}
      <StyledPrimaryDisplay>
        <StyledPrimaryResult>
          <StyledPrimaryLabel>PROFIT / MONTH</StyledPrimaryLabel>
          <StyledPriamryValue>
            <CurrencyFormat
              thousandSeparator
              value={performanceInfo.monthlyCashflow}
              prefix="$"
              displayType="text"
              decimalScale={0}
            />
          </StyledPriamryValue>
        </StyledPrimaryResult>
      </StyledPrimaryDisplay>
      <StyledSecondaryDisplay>
        <StyledSecondaryDisplayRow>
          <StyledResult>
            <StyledResultLabel>ROI</StyledResultLabel>
            {Number.isFinite(performanceInfo.yearlyRoI) ? (
              <CurrencyFormat
                thousandSeparator
                value={performanceInfo.yearlyRoI}
                suffix="%"
                displayType="text"
                decimalScale={2}
              />
            ) : (
              performanceInfo.yearlyRoI
            )}
          </StyledResult>
          <StyledResult>
            <StyledResultLabel>COC</StyledResultLabel>
            {Number.isFinite(performanceInfo.yearlyCoC) ? (
              <CurrencyFormat
                thousandSeparator
                value={performanceInfo.yearlyCoC}
                suffix="%"
                displayType="text"
                decimalScale={2}
              />
            ) : (
              performanceInfo.yearlyCoC
            )}
          </StyledResult>
        </StyledSecondaryDisplayRow>
        <StyledSecondaryDisplayRow>
          <StyledResult>
            <StyledResultLabel>INCOME</StyledResultLabel>
            <CurrencyFormat
              thousandSeparator
              value={performanceInfo.monthlyIncome}
              prefix="$"
              displayType="text"
              decimalScale={0}
            />
          </StyledResult>
          <StyledResult>
            <StyledResultLabel>EXPENSES</StyledResultLabel>
            <CurrencyFormat
              value={performanceInfo.monthlyExpenses}
              thousandSeparator
              prefix="$"
              displayType="text"
              decimalScale={0}
            />
          </StyledResult>
          <StyledResult>
            <StyledResultLabel>Monthly Payment</StyledResultLabel>
            <CurrencyFormat
              value={performanceInfo.monthlyPayment}
              thousandSeparator
              prefix="$"
              displayType="text"
              decimalScale={0}
            />
          </StyledResult>
        </StyledSecondaryDisplayRow>
      </StyledSecondaryDisplay>
    </StyledResultBlock>
  );
};
