import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ResetPasswordPage } from './resetPasswordPage';

interface IProps {}

export const ResetPassword: React.FC<IProps> = (props) => {
  const location = useLocation();
  // @ts-ignore
  const email = location.state?.from?.pathname || '/client';

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12} md={6}>
        {/* <NewToImage /> */}
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10} lg={8}>
            <ResetPasswordPage />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
