import React from 'react';
import classes from './DonutChart.module.scss';
interface IState {
  donut: any;
}

interface LegendItem {
  label: string;
  value: string;
}
interface IProps {
  chartData: Array<string>;
  legend: Array<LegendItem>;
  primaryDisplay: { label: string; value: string };
}
const colors = ['fuschia', 'lemon-lime', 'blue', 'orange', 'yellow'];
class DonutChart extends React.Component<IProps, IState> {
  public state: IState = {
    donut: {
      viewBox: '0 0 42 42',
      radius: 15.91549430918954,
      cx: 21,
      cy: 21,
      hole: {
        fill: '#fff',
      },
      segments: [
        {
          fill: 'transparent',
          stroke: '#ce4b99',
          strokeWidth: 3,
          strokeDasharray: '40 60',
          strokeDashoffset: '25',
        },
        {
          fill: 'transparent',
          stroke: '#b1c94e',
          strokeWidth: 3,
          strokeDasharray: '20 80',
          strokeDashoffset: '85',
        },
        {
          fill: 'transparent',
          stroke: '#377bbc',
          strokeWidth: 3,
          strokeDasharray: '30 70',
          strokeDashoffset: '65',
        },
        {
          fill: 'transparent',
          stroke: '#f5bd06',
          strokeWidth: 3,
          strokeDasharray: '10 90',
          strokeDashoffset: '35',
        },
        {
          fill: 'transparent',
          stroke: '#343565',
          strokeWidth: 3,
          strokeDasharray: '10 90',
          strokeDashoffset: '35',
        },
      ],
    },
  };

  calculateDonut = (values: Array<any>) => {
    const { donut } = this.state;
    const sum = values.reduce((prev, curr) => prev * 1 + curr * 1);

    let prevOffset = 25;

    donut.segments.forEach((segment, idx) => {
      let newLength = (values[idx] / sum) * 100;

      segment.strokeDasharray = `${newLength} ${100 - newLength}`;
      segment.strokeDashoffset = prevOffset;
      prevOffset = prevOffset + (100 - newLength);
    });

    return donut;
  };

  render() {
    const { chartData } = this.props;

    const donut = this.calculateDonut(chartData);

    return (
      <div>
        <figure>
          <div className={classes['figure-content']}>
            <svg
              width="100%"
              height="100%"
              viewBox={donut.viewBox}
              className="donut"
            >
              {/* <circle className="donut-hole" cx={donut.cx} cy={donut.cy} r={donut.radius} fill={donut.hole.fill}></circle> */}

              {donut.segments.map((segment, idx) => (
                <circle
                  key={idx}
                  className="donut-segment"
                  cx={donut.cx}
                  cy={donut.cy}
                  r={donut.radius}
                  fill={segment.fill}
                  stroke={segment.stroke}
                  strokeWidth={segment.strokeWidth}
                  strokeDasharray={segment.strokeDasharray}
                  strokeDashoffset={segment.strokeDashoffset}
                ></circle>
              ))}

              <g className={classes['chart-text']}>
                <text x="50%" y="50%" className={classes['chart-number']}>
                  {this.props.primaryDisplay.value}
                </text>
                <text x="50%" y="50%" className={classes['chart-label']}>
                  {this.props.primaryDisplay.label}
                </text>
              </g>
            </svg>
          </div>
          <figcaption className={classes['figure-key']}>
            <ul
              className={classes['figure-key-list']}
              aria-hidden="true"
              role="presentation"
            >
              {this.props.legend.map((item, idx) => (
                <li key={item.label}>
                  <span
                    className={`${classes['shape-circle']} ${
                      classes[`shape-${colors[idx]}`]
                    }`}
                  ></span>
                  {item.label} {item.value}
                </li>
              ))}
            </ul>
          </figcaption>
        </figure>
      </div>
    );
  }
}
export default DonutChart;
