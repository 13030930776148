import React from 'react';
import {
  Auth,
  signInWithEmailAndPassword,
  signOut,
  User,
  createUserWithEmailAndPassword,
  UserCredential,
  sendSignInLinkToEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { useUser, useAuth as useFirebaseAuth, useSigninCheck } from 'reactfire';
import { useLocation, Navigate } from 'react-router-dom';
import { useAddUser } from '../Users/useUsers';

const actionCodeSettings = {
  url: 'http://localhost:3000/shared/3/mUbaxXwIWndJE0H6QVDXjqd0zxW2',
  handleCodeInApp: true,
};

interface AuthProps {
  children: React.ReactNode;
}

interface AuthContextType {
  user: User | null;
  firebaseAuth: Auth;
  resetPassword(email: string): void;
  signUpWithLinkToEmail(email: string): void;
  signupWithGoogle(): void;
  signIn(credentials: Credentials): void;
  signUp(
    credentials: Credentials,
    admin?: boolean
  ): Promise<UserCredential | null>;
  signOut(): void;
}

export interface Credentials {
  email: string;
  password: string;
  confirmPassword?: string;
  name?: string;
}
let AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider: React.FC<AuthProps> = (props) => {
  const { status, data: user } = useUser();
  const firebaseAuth = useFirebaseAuth();
  const addUser = useAddUser();

  const signUpUser = async (credentials: Credentials, admin?: boolean) => {
    try {
      const res = await createUserWithEmailAndPassword(
        firebaseAuth,
        credentials.email,
        credentials.password
      );
      await sendEmailVerification(res.user, actionCodeSettings)
        .then((res) => {
          console.log('send email verification res: ', res);
          return true;
        })
        .catch((e) => {
          console.log('sendEmail error: ', e);
          return true;
        });
      addUser({
        name: credentials.email,
        email: credentials.email,
        admin,
        authId: res.user.uid,
      });

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const signupUserWithEmailLink = async (email: string, admin?: boolean) => {
    try {
      console.log('signInUser: A', email);

      await sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings);
      window.localStorage.setItem('dt_emailForSignIn', email);
      console.log('signedInuser: A', email);

      return true;
    } catch (e) {
      console.log('signedInuser: error', e);

      return Promise.reject(e);
    }
    console.log('signed up with user email link: ', email, actionCodeSettings);
  };

  const signInUser = async (credentials: Credentials) => {
    try {
      await signInWithEmailAndPassword(
        firebaseAuth,
        credentials.email,
        credentials.password
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const signOutUser = async () => {
    try {
      await signOut(firebaseAuth);
    } catch (e) {
      console.log('Error Signing out: ', e);
    }
  };

  const signupWithGoogle = async () => {
    const provider = await new GoogleAuthProvider();
    return signInWithPopup(firebaseAuth, provider);
  };

  const resetUserPassword = async (email: string) => {
    // console.log('jax reset called: ', email);
    try {
      console.log('jax making request: ');
      const res = await sendPasswordResetEmail(firebaseAuth, email);
      console.log('jax made request: ', res);
    } catch (e) {
      console.log('jax error: ', e);
      return Promise.reject(e);
    }
  };
  if (status === 'loading') {
    return <span>LOADING...</span>;
  }

  const value = React.useMemo(() => {
    return {
      user,
      firebaseAuth,
      resetPassword: resetUserPassword,
      signIn: signInUser,
      signUpWithLinkToEmail: signupUserWithEmailLink,
      signOut: signOutUser,
      signUp: signUpUser,
      signupWithGoogle,
    };
  }, [
    user,
    firebaseAuth,
    resetUserPassword,
    signInUser,
    signupUserWithEmailLink,
    signOutUser,
    signUpUser,
    signupWithGoogle,
  ]);
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthRequired = (props) => {
  const { status, data: fireUser } = useSigninCheck();

  const location = useLocation();
  if (status === 'loading') {
    return <div>Loading...</div>;
  }
  if (!fireUser?.signedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <>{props.children}</>;
};
