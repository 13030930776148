import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Input,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import * as CurrencyFormat from 'react-currency-format';
import { CalculatorField, useTuroFields } from './useTuroFields';
import {
  getFieldByKey,
  groupBy,
  noDivideByZero,
  ensureValidValue,
} from './utils';
import { ResultBlock } from './ResultBlock';
import { accessLevel } from '../../Widgets/Widgets';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
`;

const StyledBox = styled(Box)`
  flex-grow: 1;
  overflow: auto;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  text-transform: uppercase;
`;

const StyledFormControl = styled(FormControl)`
  margin-bottom: 16px;
  width: 100%;
`;

interface IProps {
  onrender: any;
  ondestroy: any;
  accessLevel: accessLevel;
  authUrl?: string;
  apikey?: string;
}

const groupIdtoLabel = {
  '1': 'Vehicle Details',
  '2': 'Monthly Income',
  '3': 'Monthly Expenses',
};

const getFields = (
  fields: CalculatorField[],
  field: CalculatorField,
  value: number | undefined
): CalculatorField[] => {
  let updatedFields = fields;
  if (field.key === 'purchasePrice') {
    const downPayment = getFieldByKey(fields, 'downPayment');
    const loanAmount = getFieldByKey(fields, 'principal');
    const downPaymentPercent =
      downPayment.value / noDivideByZero(field.value) || 0.1;
    downPayment.value = downPaymentPercent * (value || 0);
    loanAmount.value = (value || 0) - downPayment.value;
    updatedFields = updatedFields.map((f) => {
      return field.key === f.key ? { ...field, value: value || 0 } : f;
    });
    updatedFields = updatedFields.map((f) => {
      return downPayment.key === f.key
        ? { ...downPayment, value: downPayment.value }
        : f;
    });
    updatedFields = updatedFields.map((f) => {
      return loanAmount.key === f.key
        ? { ...loanAmount, value: loanAmount.value }
        : f;
    });
  } else if (field.key === 'downPayment') {
    const purchasePrice = getFieldByKey(fields, 'purchasePrice');
    let loanAmount = getFieldByKey(fields, 'principal');

    if (value && value > purchasePrice.value) {
      value = purchasePrice.value;
    }

    if (!value || value < 0) {
      value = 0;
    }
    loanAmount.value = Number(loanAmount.value) + (field.value - value);
    updatedFields = updatedFields.map((f) => {
      return field.key === f.key ? { ...field, value: value } : f;
    });
    updatedFields = updatedFields.map((f) => {
      return loanAmount.key === f.key
        ? { ...loanAmount, value: loanAmount.value }
        : f;
    });
  } else if (field.key === 'principal') {
    const purchasePrice = getFieldByKey(fields, 'purchasePrice');

    let downPayment = getFieldByKey(fields, 'downPayment');
    if (value && value > purchasePrice.value) {
      value = purchasePrice.value;
    }

    if (!value || value < 0) {
      value = 0;
    }
    downPayment.value = Number(downPayment.value) + (field.value - value);
    updatedFields = updatedFields.map((f) => {
      return field.key === f.key ? { ...field, value: value } : f;
    });
    updatedFields = updatedFields.map((f) => {
      return downPayment.key === f.key
        ? { ...downPayment, value: downPayment.value }
        : f;
    });
  } else {
    updatedFields = updatedFields.map((f) => {
      return field.key === f.key
        ? { ...field, value: ensureValidValue(value, field) }
        : f;
    });
  }
  return updatedFields;
};

const InputHandler: React.FC<{
  field: CalculatorField;
  onChange?(value: number | undefined): void;
}> = ({ field, onChange }) => {
  const handleChange = (evt: React.SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    const value = Number(target.value);
    onChange?.(value || 0);
  };

  const handleSelectChangeEvent = (evt: SelectChangeEvent) => {
    const target = evt.target;
    const value = Number(target.value);
    onChange?.(value || 0);
  };
  const handleFormattedChange = (valObj) => {
    console.log('valObj: ', valObj);
    onChange?.(valObj.floatValue);
  };

  const customInput = React.useMemo(
    () => (props) => {
      return (
        <Input className="clear-icon" {...props} style={{ width: '100%' }} />
      );
    },
    []
  );
  return (
    <>
      {field.type === 'text' && (
        <Input id={field.key} value={field.value} onChange={handleChange} />
      )}
      {field.type === 'numeric' && (
        <CurrencyFormat
          customInput={customInput}
          value={field.value}
          thousandSeparator
          onValueChange={handleFormattedChange}
          type="tel"
          decimalScale={0}
        />
      )}
      {field.type === 'currency' && (
        <CurrencyFormat
          customInput={customInput}
          value={field.value}
          thousandSeparator
          prefix="$"
          onValueChange={handleFormattedChange}
          type="tel"
          decimalScale={0}
        />
      )}
      {field.type === 'percent' && (
        <CurrencyFormat
          customInput={customInput}
          value={field.value}
          thousandSeparator
          suffix="%"
          onValueChange={handleFormattedChange}
          type="tel"
          decimalScale={2}
        />
      )}
      {field.type === 'lov' && (
        <Select
          labelId={`${field.key}-label`}
          id={`${field.key}-select`}
          value={field.value}
          onChange={(evt) => handleSelectChangeEvent(evt)}
          label={field.label}
        >
          {field.options?.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};
const TuroCalculator: React.FC<IProps> = (props) => {
  const calculatorFields = useTuroFields();
  const [fields, setFields] =
    React.useState<CalculatorField[]>(calculatorFields);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [trialCount, setTrialCount] = React.useState<number | null>(null);
  const [randomId, setRandomId] = React.useState<number>(Math.random());
  const [activeAccordion, setActiveAccordion] = React.useState<
    keyof typeof groupIdtoLabel | ''
  >('1');
  const getLsKey = () => `dt_3_${props.apikey || ''}`;
  const initializeTrial = (): number => {
    console.log('initializing trial: ', props.apikey);
    const lsKey = getLsKey();
    const trial = localStorage.getItem(lsKey);
    const tCount = '3';
    if (trial) {
      return Number(trial);
    } else {
      localStorage.setItem(lsKey, tCount);
      return Number(tCount);
    }
  };
  const groupedFields = groupBy(fields, 'groupId');
  const onChange = (field: CalculatorField) => (value: number | undefined) => {
    const updatedFields = getFields(fields, field, value);
    // const random = Math.random();
    setHasChanges(true);
    setFields([...updatedFields]);
    // setRandomId(random);
  };

  const handleTogglePanel = (panel) => () => {
    setActiveAccordion(panel === activeAccordion ? '' : panel);
  };

  const handleCalculate = (event: React.SyntheticEvent) => {
    event.preventDefault();
    switch (props.accessLevel) {
      case 'all':
        setRandomId(Math.random());
        setHasChanges(false);
        break;
      case 'trial':
        break;
      case 'none':
      default:
        if (props.authUrl && !trialCount) {
          window.location.href = props.authUrl;
        }

        if (trialCount) {
          const count = trialCount - 1;
          setTrialCount(count);
          localStorage.setItem(getLsKey(), count.toString());
          setRandomId(Math.random());
          setHasChanges(false);
        }
        break;
    }
    return true;
  };
  React.useEffect(() => {
    props.onrender();
    if (props.accessLevel !== 'all') {
      setTrialCount(initializeTrial());
    }
    return () => {
      props.ondestroy();
    };
  }, []);
  return (
    <StyledContainer>
      <ResultBlock
        fields={fields}
        randomId={randomId}
        accessLevel={props.accessLevel}
      />
      <StyledBox
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleCalculate}
      >
        <div>
          {Object.keys(groupedFields).map((groupId) => (
            <Accordion
              key={groupId}
              expanded={groupId === activeAccordion}
              onChange={handleTogglePanel(groupId)}
            >
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {groupIdtoLabel[groupId]}
              </StyledAccordionSummary>
              <AccordionDetails>
                {groupedFields[groupId]
                  .filter((field) => !field.hidden)
                  .map((field) => (
                    <StyledFormControl key={field.key} variant="standard">
                      <InputLabel>{field.label}</InputLabel>
                      <InputHandler field={field} onChange={onChange(field)} />
                    </StyledFormControl>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <div>
          <Button fullWidth variant="contained" type="submit">
            {trialCount === 0 ? 'Buy Now' : 'Calculate'}
          </Button>
          {trialCount ? (
            <Grid
              container
              justifyContent={'center'}
              style={{ padding: '12px' }}
            >
              <Grid>
                <span style={{ color: 'red' }}>{trialCount}</span> credits
                remain
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </div>
      </StyledBox>
    </StyledContainer>
  );
};

export default TuroCalculator;
