import React, { useState } from 'react';
import { Form } from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { Box, Button, CircularProgress, Grid } from '@mui/material';

const schema: RJSFSchema = {
  type: 'object',
  required: ['email'],
  properties: {
    email: { type: 'string', title: 'Email', format: 'email' },
  },
};
const log = (type) => console.log.bind(console, type);
export const ResetPasswordPage = () => {
  const auth = useAuth();
  const [isSendingEmail, setIsSendingEmail] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const [firebaseError, setFirebaseError] = useState('');
  const sendResetEmail = async (creds) => {
    try {
      setIsSendingEmail(true);
      setIsSuccess(false);
      await auth.resetPassword(creds.email);
      setIsSuccess(true);

      setIsSendingEmail(false);
      setFirebaseError('');
    } catch (e: any) {
      setIsSendingEmail(false);
      setIsSuccess(false);
      if (e.code == 'auth/invalid-email') {
        setFirebaseError('Invalid Email');
      } else if (
        e.code == 'auth/user-not-found' ||
        e.code === 'auth/wrong-password'
      ) {
        setFirebaseError('Email not found');
      } else {
        setFirebaseError(`Unknown error occurred: ${e.code}`);
      }
    }
  };
  const handleSendResetPasswordEmail = (form) => {
    log('submitted');
    sendResetEmail(form.formData);
  };
  return (
    <Box>
      <h1>Let's reset your password!</h1>
      <p>We will send you an email to reset your password.</p>
      <Form
        schema={schema}
        validator={validator}
        onSubmit={handleSendResetPasswordEmail}
      >
        <Grid>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            disabled={isSendingEmail}
          >
            {isSendingEmail ? (
              <CircularProgress color="secondary" />
            ) : (
              'Send Reset Email'
            )}
          </Button>
        </Grid>
      </Form>
      {isSuccess && <p>A password reset email has been sent. </p>}
      <p>
        <Link to="/login">Back to login</Link>
      </p>

      <div style={{ color: 'red' }}>{firebaseError}</div>
    </Box>
  );
};
