import React from 'react';
import { useParams } from 'react-router-dom';
import { Paywall } from './Paywall';
import { useAuth, Credentials } from '../Login/AuthProvider';
import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

interface PaywallContextType {
  submitPaywall(credentials: Credentials): void;
}

interface PaywallProps {
  children: React.ReactNode;
}

let PaywallContext = React.createContext<PaywallContextType>(null!);

export const PaywallProvider: React.FC<PaywallProps> = (props) => {
  const { signUp } = useAuth();

  const submitPaywall = async (creds: Credentials) => {
    try {
      await signUp(creds, false);
      return true;
    } catch (e) {
      return false;
    }
  };
  return (
    <PaywallContext.Provider
      value={{
        submitPaywall,
      }}
    >
      {props.children}
    </PaywallContext.Provider>
  );
};

export const usePaywall = () => {
  return React.useContext(PaywallContext);
};

// TODO(JK): Deprecated but functionality may eventually be usefull
const PaywallRequired = (props) => {
  const { user } = useAuth();
  const params = useParams();

  const firestore = useFirestore();
  const transactionsCollection = collection(firestore, 'transactions');
  const transactionsQuery = query(
    transactionsCollection,
    where('userId', '==', user?.uid || ''),
    where('productId', '==', params.productId),
    where('referrerUserId', '==', params.uuid)
  );
  const {
    status,
    data: userTransactions = [],
    error,
  } = useFirestoreCollectionData(transactionsQuery, {
    idField: 'id', // this field will be added to the object created from each document
  });
  if (status === 'error') {
    console.log('An error here: ', error);
  }
  if (status === 'loading') {
    return <div>Loading product...</div>;
  }
  if (!userTransactions.length) {
    return <Paywall />;
  }

  return <>{props.children}</>;
};
