import { useMutation, useQuery } from 'react-query';
import { HOSTNAME } from '..';

export const useStripePaymentIntentCreate = (product) => {
  return useQuery(
    ['paymentIntent', { id: product.id, amount: product.amount }],
    () => {
      console.log('[fetching]', product);
      return fetch(`${HOSTNAME}/api/create-payment-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          items: [{ id: product.id, amount: product.amount }],
        }),
      }).then((res) => res.json());
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: undefined,
    }
  );
};

export const useStripeCheckout = () => {
  return useMutation(
    ['checkout'],
    (product: { id: string; amount: string }) => {
      console.log('[fetching]', product);
      return fetch(`${HOSTNAME}/api/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          items: [{ id: product.id, amount: product.amount }],
        }),
      }).then((res) => {
        console.log('resHERE: ', res);
        return res.json();
      });
    },
    {
      onSuccess: (res) => {
        debugger;
        window.location.href = res.sessionurl;
      },
      onError: (res) => {
        debugger;
      },
    }
  );
};
