import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '9ab2b0d2c101c574f1409ec47e7f4b05',
  plugins: [new BugsnagPluginReact()],
});

export const WithBugsnag = (props) => {
  const plugin = Bugsnag?.getPlugin('react');
  if (!plugin) {
    console.error('Failed to load bugsnag');
    return <div> Failed to load</div>;
  }
  const BugsnagErrorBoundary = plugin.createErrorBoundary(React);
  return <BugsnagErrorBoundary>{props.children}</BugsnagErrorBoundary>;
};
